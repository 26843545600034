import * as validation from './typeValidations';

export function validateInputParameters(id: string, query: string, pageNumber: number, pageSize: number): void {
  validation.validateInputParameter(id, 'id', validation.isNonEmptyString);
  validation.validateInputParameter(query, 'query', validation.isString);
  validation.validateInputParameter(pageNumber, 'pageNumber', validation.isPositiveInteger);
  validation.validateInputParameter(pageSize, 'pageSize', validation.isPositiveInteger);
}

export function getElasticSearchString(query: string): string {
  const words = query.split(/\s+/);
  const wordsWithParanthesisAndWildCard = words.map((w) => `(*${w}*)`);
  const elasticSearchString = wordsWithParanthesisAndWildCard.join(' AND ');
  return elasticSearchString;
}

export function getOffset(pageNumber: number, pageSize: number): number {
  let offset = 0;
  if (pageNumber > 1) {
    offset = (pageNumber - 1) * pageSize;
  }

  return offset;
}
