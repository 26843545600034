enum ComponentPropTypes {
  Loading = 'Loading',
  ProductTemplateSelector = 'ProductTemplateSelector',
  SelectZoneToZone = 'SelectZoneToZone',
  SelectRadiusZone = 'SelectRadiusZone',
  ProductSelector = 'ProductSelector',
  AddRecipients = 'AddRecipients',
  Header = 'Header',
  BackButton = 'BackButton',
  OrderConfirmed = 'OrderConfirmed',
  SummaryAndOrder = 'SummaryAndOrder',
  ProductCategory = 'ProductCategory',
  NoZonesRequired = 'NoZonesRequired',
  ProfileSelector = 'ProfileSelector',
}

export default ComponentPropTypes;
