import { LocaleText, LocaleTextJson, mapLocaleText } from './localeText';
import { mapPriceForNumberOfZones, PriceForNumberOfZones, PriceForNumberOfZonesJson } from './priceForNumberOfZones';

export interface ProductJson {
  id: number;
  profileDescription: LocaleTextJson;
  profileId: number;
  profileName: LocaleTextJson
  prices: PriceForNumberOfZonesJson[];

}

export interface Product {
  id: number;
  profileDescription: LocaleText;
  profileId: number;
  profileName: LocaleText
  prices: PriceForNumberOfZones[];
}

export const mapProduct = (json: ProductJson): Product => ({
  id: json.id,
  profileDescription: mapLocaleText(json.profileDescription),
  profileId: json.profileId,
  profileName: mapLocaleText(json.profileName),
  prices: json.prices.map(mapPriceForNumberOfZones),
});
