import { AuthenticationService } from '../../types/authenticationService';
import { Order } from '../../types/order';
import { OrderAndTicket } from '../../types/orderAndTicket';
import { ProductTemplate } from '../../types/productTemplate';
import { RadiusTicketRequestData } from '../../types/RadiusTicketRequestData';
import { SearchResult } from '../../types/searchResult';
import { Zone } from '../../types/zone';
import { ZoneToZoneTicketRequestData } from '../../types/ZoneToZoneTicketRequestData';
import ApiBaseClient from '../apiBaseClient';
import * as orders from './orders';
import * as productTemplates from './productTemplates';
import * as tickets from './tickets';
import * as zones from './zones';

export default class TicketApiClient {
  public zones;

  public productTemplates;

  public orders;

  public tickets;

  constructor(authenticationService: AuthenticationService, baseUrl: string) {
    const apiClient = new ApiBaseClient(baseUrl, authenticationService);

    this.zones = {
      getAll: async ():Promise<Zone[]> => zones.getAll(apiClient),
      calculate: async (zoneFrom: string, zoneTo: string): Promise<number> => zones.calculate(apiClient, zoneFrom, zoneTo),
    };
    this.productTemplates = {
      getAll: async (assertPriceForEveryZone = true): Promise<ProductTemplate[]> => productTemplates.getAll(apiClient, assertPriceForEveryZone),
    };
    this.orders = {
      getById: async (orderId: string): Promise<Order> => orders.getById(apiClient, orderId),
      getByCompanyId: async (
        companyId: string,
        query: string,
        pageNumber: number,
        pageSize: number,
      ): Promise<SearchResult<Order>> => orders.getByCompanyId(apiClient, companyId, query, pageNumber, pageSize),
    };
    this.tickets = {
      getByCompanyId: async (
        companyId: string,
        query: string,
        pageNumber: number,
        pageSize: number,
      ): Promise<SearchResult<OrderAndTicket>> => tickets.getByCompanyId(apiClient, companyId, query, pageNumber, pageSize),
      getByOrderId: async (
        orderId: string,
        query: string,
        pageNumber: number,
        pageSize: number,
      ):Promise<SearchResult<OrderAndTicket>> => tickets.getByOrderId(apiClient, orderId, query, pageNumber, pageSize),
      cancelPending: async (ticketId: string): Promise<void> => tickets.cancelPending(apiClient, ticketId),
      resendPickupCode: async (ticketId: string): Promise<void> => tickets.resendPickupCode(apiClient, ticketId),
      createRadiusTicket: async (data: RadiusTicketRequestData): Promise<void> => tickets.createRadiusTicket(apiClient, data),
      createZoneToZoneTicket: async (data: ZoneToZoneTicketRequestData): Promise<void> => tickets.createZoneToZoneTicket(apiClient, data),
    };
  }
}
