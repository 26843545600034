import ApiBaseClient from '../apiBaseClient';
import * as searchUtils from '../searchUtils';
import {
  mapOrder, Order, OrderJson, validateOrder,
} from '../../types/order';
import { SearchResult } from '../../types/searchResult';
import * as validation from '../typeValidations';

export async function getById(apiClient: ApiBaseClient, orderId: string): Promise<Order> {
  validation.validate(orderId, 'orderId', validation.isNonEmptyString);
  const result = await apiClient.get<OrderJson>(`/order/by-id/${orderId}`);
  validateOrder(result.data, 'response');
  return mapOrder(result.data);
}

export async function getByCompanyId(apiClient: ApiBaseClient, companyId: string, query: string, pageNumber: number, pageSize: number): Promise<SearchResult<Order>> {
  searchUtils.validateInputParameters(companyId, query, pageNumber, pageSize);

  const elasticSearchString = searchUtils.getElasticSearchString(query);
  const offset = searchUtils.getOffset(pageNumber, pageSize);
  const result = await apiClient.get<SearchResult<OrderJson>>(`/order/by-company-id/${companyId}?searchFor=${elasticSearchString}&offset=${offset}&maxEntries=${pageSize}`);

  validation.validate(result.data, 'response', validation.isObject);
  validation.validate(result.data.totalEntries, 'response.totalEntries', validation.isNonNegativeInteger);
  validation.validate(result.data.matches, 'response.matches', validation.isArray);
  result.data.matches.forEach((order, i) => validateOrder(order, `response.matches[${i}]`));

  return {
    totalEntries: result.data.totalEntries,
    matches: result.data.matches.map(mapOrder),
  };
}
