import parsePhoneNumberFromString from 'libphonenumber-js/max';
import { useEffect, useState } from 'react';
import { AuthenticationService } from '..';
import TicketApiClient from '../apiClient/ticketApi/ticketApiClient';
import { ComponentProps } from '../components/componentProps';
import ComponentPropTypes from '../components/componentPropTypes';
import { PriceParams } from '../components/ProductTemplateSelector';
import { getDefaultRow } from '../components/Recipients';
import { Product } from '../types/product';
import { ProductCategory } from '../types/productCategory';
import { ProductTemplate } from '../types/productTemplate';
import { Profile } from '../types/profile';
import { RecipientBatch } from '../types/RecipientBatch';
import { SelectedZoneToZone, SelectedZoneToZoneCalculationError } from '../types/selectedZones';
import {
  getZoneById,
  NO_ZONE, Zone, ZoneTo,
} from '../types/zone';
import Page from './page';
import productTemplateHelper from './productTemplateHelper';
import SELECTED_LANGUAGE from './selectedLanguage';
import { WizardStateHandler } from './wizardStateHandler';

const useTromsStateHandler: WizardStateHandler = (
  authService: AuthenticationService, header: string, errorLink: string, agreementNumber: string, apiBasePath: string,
) => {
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | null>(null);
  const [zones, setZones] = useState<Zone[] | null>(null);
  const [selectedProductTemplate, setSelectedProductTemplate] = useState<undefined | ProductTemplate>(undefined);
  const [currentPage, setCurrentPage] = useState(Page.First);
  const [selectedProductCategory, setSelectedProductCategory] = useState<undefined | ProductCategory>(undefined);
  const [selectedZones, setSelectedZones] = useState<null | SelectedZoneToZone | SelectedZoneToZoneCalculationError>(null);
  const [selectedProduct, setSelectedProduct] = useState<undefined | Product>(undefined);
  const [calculatingDistance, setCalculatingDistance] = useState(false);
  const [recipients, setRecipients] = useState([getDefaultRow(1)]);
  const [recipientBatches, setRecipientBatches] = useState<RecipientBatch[]>([]);
  const [orderFailed, setOrderFailed] = useState(false);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [invoiceReference, setInvoiceReference] = useState('');
  const [personalReference, setPersonalReference] = useState('');
  const [selectedProfile, setSelectedProfile] = useState<Profile | undefined>(undefined);

  const productCategories: ProductCategory[] = [
    { id: 'single-ticket-bus', text: 'Enkeltbillett buss' },
    { id: 'period-ticket-bus', text: 'Periodebillett buss' },
    { id: 'youth-total', text: 'Ungdom total' },
  ];

  const ticketApiClient = new TicketApiClient(authService, apiBasePath);
  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        const productsRequest = ticketApiClient.productTemplates.getAll(false);
        const zonesRequest = ticketApiClient.zones.getAll();

        await Promise.all([productsRequest, zonesRequest]).then((results) => {
          setProductTemplates(results[0]);
          setZones(results[1]);
        });
      } catch (e: unknown) {
        setZones(() => { throw e; });
      }
    };
    fetch();
  }, []);

  const order = (): void => {
    if (orderInProgress) {
      return;
    }

    setOrderInProgress(true);
    setOrderFailed(false);

    if (!selectedZones) {
      throw new Error('selectedZones must be set');
    }
    if (!selectedProduct) {
      throw new Error('selectedProduct must be set');
    }

    const recipientsContract = [];

    recipientBatches.forEach((batch) => {
      batch.validPhoneNumbers.forEach((number) => {
        recipientsContract.push({
          phone: number.phone.replace(/\s/g, ''),
          phoneCountryCode: number.phoneCountryCode,
          count: batch.ticketCount,
        });
      });
    });

    recipientsContract.push(...recipients.filter((x) => x.phoneNumber).map((x) => {
      const parsed = parsePhoneNumberFromString(x.phoneNumber);
      if (!parsed) {
        throw new Error(`could not parse phonenumber ${x.phoneNumber}`);
      }

      return {
        phone: parsed.nationalNumber.toString(),
        phoneCountryCode: `+${parsed.countryCallingCode}`,
        count: x.count,
      };
    }));

    const orderPromise = ticketApiClient.tickets.createZoneToZoneTicket({
      agreementNumber,
      invoiceReference,
      personalReference,
      productId: selectedProduct.id,
      zoneFrom: selectedZones.zoneFrom.id,
      zoneTo: selectedZones.zoneTo === NO_ZONE ? undefined : selectedZones.zoneTo.id,
      recipients: recipientsContract,
    });

    orderPromise
      .then(() => {
        setOrderInProgress(false);
        setCurrentPage(Page.Forth);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line
        setOrderInProgress(false);
        setOrderFailed(true);
      });
  };

  const selectZoneZoneToZone = async (zoneFrom: Zone, zoneTo: ZoneTo): Promise<void> => {
    if (!productTemplates) {
      throw new Error('productTemplates must be set');
    }
    if (!selectedProductCategory) {
      throw new Error('selectedProductCategory must be set');
    }

    let newSelectedZones: SelectedZoneToZone | SelectedZoneToZoneCalculationError | undefined;

    if (zoneTo === NO_ZONE) {
      newSelectedZones = {
        zoneFrom,
        zoneTo,
        zoneCount: 1,
        zoneType: 'ZoneToZone',
        userConfirmed: selectedZones?.userConfirmed || false,
      };
    } else {
      try {
        setCalculatingDistance(true);
        const result = await ticketApiClient.zones.calculate(zoneFrom.id, zoneTo.id);
        setCalculatingDistance(false);

        newSelectedZones = {
          zoneFrom,
          zoneTo,
          zoneCount: result,
          zoneType: 'ZoneToZone',
          userConfirmed: selectedZones?.userConfirmed || false,
        };
      } catch (e: unknown) {
        setCalculatingDistance(false);
        newSelectedZones = {
          zoneType: 'CalculationError',
          zoneFrom,
          zoneTo,
          userConfirmed: selectedZones?.userConfirmed || false,
          zoneCount: selectedZones?.zoneCount || 1,
        };
      }
    }
    setSelectedZones(newSelectedZones);

    if (selectedProductTemplate) {
      let newProductTemplates = productTemplateHelper.filterByProductCategoryId(productTemplates, selectedProductCategory.id);
      newProductTemplates = productTemplateHelper.filterByZoneToZone(productTemplates, newSelectedZones);

      if (selectedProfile?.id) {
        newProductTemplates = productTemplateHelper.filterByProfile(newProductTemplates, selectedProfile.id);
      }

      if (!newProductTemplates.find((x) => x.id === selectedProductTemplate.id)) {
        setSelectedProduct(undefined);
        setSelectedProductTemplate(undefined);
      }
    }
  };

  const confirmZoneSelection = (): void => {
    if (!selectedZones) {
      throw new Error('selectedZones can not be null');
    }

    setSelectedZones({
      ...selectedZones,
      userConfirmed: true,
    });
  };

  const resetWidget = (): void => {
    setSelectedProductCategory(undefined);
    setSelectedProductTemplate(undefined);
    setSelectedZones(null);
    setSelectedProduct(undefined);
    setRecipients([getDefaultRow(1)]);
    setRecipientBatches([]);
    setCalculatingDistance(false);
    setOrderInProgress(false);
    setOrderFailed(false);
    setInvoiceReference('');
    setPersonalReference('');
    setCurrentPage(Page.First);
    setSelectedProfile(undefined);
  };

  const goBack = (): void => {
    let newPage;

    if (currentPage === Page.Second) {
      newPage = Page.First;
    } else if (currentPage === Page.Third) {
      newPage = Page.Second;
    } else if (currentPage === Page.Forth) {
      resetWidget();
    }

    if (newPage) {
      setCurrentPage(newPage);
    }
  };

  const getUniqueProfiles = (productTemplateList: ProductTemplate[]): Profile[] => {
    const profiles: Profile[] = [];

    productTemplateList.forEach((productTemplate) => {
      productTemplate.products.forEach((product) => {
        if (!profiles.find((x) => x.id === product.profileId)) {
          profiles.push({
            id: product.profileId,
            name: product.profileName,
            description: product.profileDescription,
          });
        }
      });
    });

    return profiles;
  };

  const selectProductCategory = (productCategory: ProductCategory): void => {
    if (!zones) {
      throw new Error('zones must have value');
    }

    if (productCategory.id === selectedProductCategory?.id) {
      setCurrentPage(Page.Second);
      return;
    }

    resetWidget();
    const zoneFrom = getZoneById(zones, 'TRO:TariffZone:54010');

    setSelectedProductCategory(productCategory);
    setCurrentPage(Page.Second);
    setSelectedZones({
      zoneType: 'ZoneToZone',
      userConfirmed: false,
      zoneCount: 1,
      zoneFrom,
      zoneTo: NO_ZONE,
    });

    if (productCategory.id === 'youth-total') {
      if (!productTemplates) {
        throw new Error('productTemplates must be set');
      }

      const productTemplate = productTemplateHelper.filterByProductCategoryId(productTemplates, productCategory.id)[0];
      const profile = getUniqueProfiles([productTemplate])[0];
      const product = productTemplate.products[0];

      setSelectedZones({
        zoneType: 'ZoneToZone',
        userConfirmed: true,
        zoneCount: 1,
        zoneFrom,
        zoneTo: NO_ZONE,
      });
      setSelectedProductTemplate(productTemplate);
      setSelectedProfile(profile);
      setSelectedProduct(product);
    }
  };

  const isProfilesDifferent = (productTemplateList: ProductTemplate[]): boolean => {
    // check to see if productTemplate contains a list of profiles that is different from other productTemplate

    const profileIdsList = productTemplateList.map((x) => x.products.map((y) => y.profileId).sort((a, b) => a - b));

    if (profileIdsList.length === 1) {
      return false;
    }

    for (let i = 1; i < profileIdsList.length; i += 1) {
      const listA = profileIdsList[i - 1];
      const listB = profileIdsList[i];

      if (listA.length !== listB.length) {
        return true;
      }

      for (let j = 0; j < listA.length; j += 1) {
        if (listA[j] !== listB[j]) {
          return true;
        }
      }
    }

    return false;
  };

  const loading = zones === null || productTemplates === null;
  const componentPropsList: ComponentProps[] = [];

  if (loading) {
    componentPropsList.push({ type: ComponentPropTypes.Loading });
  } else {
    if (currentPage === Page.First) {
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: header,
      });
      componentPropsList.push({
        type: ComponentPropTypes.ProductCategory,
        productCategories,
        selectedProductCategory,
        selectProductCategory,
      });
    }
    if (currentPage === Page.Second) {
      if (!selectedProductCategory) {
        throw new Error('selectedProductCategory must have a value');
      }
      if (!selectedZones) {
        throw new Error('selectedZones must have a value');
      }

      componentPropsList.push({
        type: ComponentPropTypes.BackButton,
        goBack,
      });
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: selectedProductCategory.text,
      });

      if (selectedProductCategory.id === 'youth-total') {
        componentPropsList.push({
          type: ComponentPropTypes.NoZonesRequired,
        });
      } else {
        componentPropsList.push({
          type: ComponentPropTypes.SelectZoneToZone,
          nrOfZonesGetAll: undefined,
          zones,
          selectedZones,
          calculatingDistance,
          selectZones: selectZoneZoneToZone,
          confirmZoneSelection,
        });
      }

      if (selectedZones.userConfirmed) {
        const productTemplatesForCategory = productTemplateHelper.filterByProductCategoryId(productTemplates, selectedProductCategory.id);
        const productTemplatesForCategoryAndZones = productTemplateHelper.filterByZoneToZone(productTemplatesForCategory, selectedZones);
        const productTemplatesForCategoryAndZonesAndProfile = selectedProfile
          ? productTemplateHelper.filterByProfile(productTemplatesForCategoryAndZones, selectedProfile.id)
          : productTemplatesForCategoryAndZones;
        const shouldSelectProfile = isProfilesDifferent(productTemplatesForCategory) || selectedProductCategory.id === 'youth-total';

        if (shouldSelectProfile) {
          const profiles = getUniqueProfiles(productTemplatesForCategory);

          componentPropsList.push({
            type: ComponentPropTypes.ProfileSelector,
            profiles,
            selectedProfile,
            selectProfile: (profile: Profile) => {
              setSelectedProfile(profile);

              if (selectedProductTemplate) {
                const product = selectedProductTemplate.products.find((x) => x.profileId === profile.id);
                setSelectedProduct(product);
              }
            },
            selectedLanguage: SELECTED_LANGUAGE,
          });
        }
        if (!shouldSelectProfile || selectedProfile) {
          let priceParams: PriceParams | undefined;
          if (selectedProfile) {
            priceParams = {
              profileId: selectedProfile.id,
              zoneCount: selectedZones.zoneCount,
            };
          }

          componentPropsList.push({
            type: ComponentPropTypes.ProductTemplateSelector,
            productTemplates: productTemplatesForCategoryAndZonesAndProfile,
            selectedLanguage: 'nb',
            selectedProductTemplate,
            selectProductTemplate: (productTemplate: ProductTemplate) => {
              setSelectedProductTemplate(productTemplate);
              if (shouldSelectProfile && selectedProfile) {
                const product = productTemplate.products.find((x) => x.profileId === selectedProfile.id);
                if (!product) {
                  throw new Error(`Could not find product with profileId=${selectedProfile.id} in productTemplate=${productTemplate.id}`);
                }
                setSelectedProduct(product);
              }
            },
            priceParams,
          });
        }

        if (selectedProductTemplate && !shouldSelectProfile) {
          componentPropsList.push({
            type: ComponentPropTypes.ProductSelector,
            selectProduct: setSelectedProduct,
            selectedProduct,
            selectedProductTemplate,
            zoneCount: selectedZones.zoneCount,
          });
        }
        if (selectedProduct) {
          if (!selectedProductTemplate) {
            throw new Error('selectedProductTemplate must have a value');
          }
          componentPropsList.push({
            type: ComponentPropTypes.AddRecipients,
            recipients,
            recipientBatches,
            selectedProduct,
            selectedProductTemplate,
            zoneCount: selectedZones.zoneCount,
            disableToSummaryAndBuyButton: calculatingDistance || selectedZones.zoneType === 'CalculationError',
            setRecipientBatches,
            setRecipients,
            gotoSummary: (): void => {
              setOrderFailed(false);
              setCurrentPage(Page.Third);
            },
          });
        }
      }
    }
    if (currentPage === Page.Third) {
      if (!selectedProductTemplate) {
        throw Error('selectedProductTemplate must have value');
      }
      if (!selectedProduct) {
        throw Error('selectedProduct must have value');
      }
      if (!selectedZones || selectedZones.zoneType === 'CalculationError') {
        throw Error('selectedZones must be valid');
      }

      componentPropsList.push({
        type: ComponentPropTypes.BackButton,
        goBack,
      });
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: 'Oppsummering',
      });
      componentPropsList.push({
        type: ComponentPropTypes.SummaryAndOrder,
        selectedProductTemplate,
        selectedProduct,
        selectedZones,
        recipients: recipients.filter((x) => x.phoneNumber), // recipients always contains an empty entry for user input
        recipientBatches,
        selectedLanguage: SELECTED_LANGUAGE,
        personalReference,
        invoiceReference,
        orderFailed,
        orderInProgress,
        errorLink,
        setInvoiceReference,
        setPersonalReference,
        order,
      });
    }
    if (currentPage === Page.Forth) {
      componentPropsList.push({
        type: ComponentPropTypes.OrderConfirmed,
        reset: resetWidget,
      });
    }
  }
  return componentPropsList;
};

export default useTromsStateHandler;
