/* eslint-disable max-len */
import React from 'react';
import { Icon } from '@ruter-ds/rds-components';

const PeriodTicketBusIcon: React.FC = () => (
  <Icon>
    <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.8 19.2C11.8 18.2 12.6 17.5 13.5 17.5C14.5 17.5 15.2 18.3 15.2 19.2C15.2 20.2 14.4 20.9 13.5 20.9C12.5 21 11.8 20.2 11.8 19.2Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.2 19.2C25.2 18.2 26 17.5 26.9 17.5C27.8 17.5 28.6 18.3 28.6 19.2C28.6 20.2 27.8 20.9 26.9 20.9C26 20.9 25.2 20.2 25.2 19.2Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.1 15.2C7.1 15.3 7.1 15.4 7.2 15.4C7.3 15.5 7.3 15.5 7.4 15.5H12.5C12.7 15.5 12.8 15.4 12.8 15.2V13.2C12.8 13.1 12.8 13 12.7 13C12.7 12.9 12.6 12.9 12.5 12.9H7.4C7.2 12.9 7.1 13 7.1 13.2V15.2ZM13.3 15.2C13.3 15.3 13.3 15.4 13.4 15.4C13.5 15.5 13.5 15.5 13.6 15.5H18.7C18.9 15.5 19 15.4 19 15.2V13.2C19 13.1 19 13 18.9 13C18.8 12.9 18.8 12.9 18.7 12.9H13.7C13.5 12.9 13.3 13 13.3 13.2V15.2ZM19.6 15.2C19.6 15.3 19.6 15.4 19.7 15.4C19.8 15.5 19.8 15.5 19.9 15.5H25.1C25.3 15.5 25.4 15.4 25.4 15.2V13.2C25.4 13.1 25.4 13 25.3 13C25.2 12.9 25.2 12.9 25.1 12.9H20C19.8 12.9 19.7 13 19.7 13.2V15.2H19.6ZM26 15.2C26 15.3 26 15.4 26.1 15.4C26.2 15.5 26.2 15.5 26.3 15.5H30.8C30.9 15.5 31 15.5 31.1 15.4C31.2 15.3 31.2 15.2 31.1 15.1L30.4 13C30.4 12.9 30.3 12.9 30.2 12.9H26.3C26.1 12.9 26 13 26 13.2V15.2ZM7 11.8H30.5C30.6 11.8 30.7 11.9 30.7 11.9L32 16V18.9C32 19 32 19.1 31.9 19.1C31.8 19.2 31.8 19.2 31.7 19.2H29.2C29.2 18 28.2 17 27 17C25.8 17 24.8 18 24.8 19.2H15.7C15.7 18 14.7 17 13.5 17C12.3 17 11.3 18 11.3 19.2H6.8C6.7 19.2 6.7 19.2 6.7 19.1L6.6 19V12.2C6.6 12 6.8 11.8 7 11.8Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40 24.5C40 25 39.6 25.5 39 25.5H7.1C6.6 25.5 6.1 25.1 6.1 24.5V5.6C6.1 5.1 6.5 4.6 7.1 4.6H11.1V6.5H13.2V4.6H32.9V6.5H35V4.6H39C39.5 4.6 40 5 40 5.6V24.5ZM1 9.6C1.5 9.6 2 10 2 10.6V28.3C2 29.2 2.7 30 3.6 30H33.7C34.2 30 34.7 30.4 34.7 31C34.7 31.6 34.3 32 33.7 32H3.6C1.6 32 0 30.4 0 28.3V10.6C0 10 0.400002 9.6 1 9.6ZM13.2 0V2.6H32.9V0H35V2.6H39C40.7 2.6 42 3.9 42 5.6V24.5C42 26.2 40.7 27.5 39 27.5H7.1C5.4 27.5 4.1 26.2 4.1 24.5V5.6C4.1 3.9 5.4 2.6 7.1 2.6H11.1V0H13.2Z" fill="#333941" />
    </svg>

  </Icon>
);

export default PeriodTicketBusIcon;
