import parseDate from '../apiClient/ticketApi/commonParsers';
import { MediaType, parseMediaType } from './mediaType';
import { parseZoneType, ZoneType } from './zoneType';
import * as validation from '../apiClient/typeValidations';

export interface Order {
  agreementNumber: string;
  failedTicketCount: number;
  id: string;
  invoiceReference: string;
  mediaType: MediaType;
  nrOfZones: number;
  orderedBy: string;
  personalReference: string;
  productTemplateId: number;
  purchaseDate: Date;
  requestedTicketCount: number;
  ticketCount: number;
  totalPrice: number;
  zoneFrom: string;
  zoneTo: string;
  zoneType: ZoneType;
  zoneVia: string;
  zones: string[];
}

export interface OrderJson {
  agreementNumber: string;
  appName: string;
  cancelUnclaimedTicketsAfterHours: number | null;
  cancelledAmount: number;
  cancelledTicketCount: number;
  cancelledVat: number;
  failedTicketCount: number;
  id: string;
  invoiceReference: string;
  invoicedCount: number;
  mediaType: string;
  nrOfZones: number| null;
  orderedBy: string;
  ownerId: number;
  passengerCount: number;
  personalReference: string;
  productId: number;
  productName: string;
  productPrice: number;
  productTemplateId: number;
  productVatAmount: number;
  profileId: number;
  profileName: string;
  purchaseDate: string;
  requestedTicketCount: number;
  ticketCount: number;
  ticketIds: string[];
  ticketType: string;
  totalPrice: number;
  totalVat: number;
  zoneFrom: string;
  zoneTo: string | null;
  zoneType: string;
  zoneVia: string | null;
  zones: string[];
}

export function mapOrder(orderAsJson: OrderJson): Order {
  return {
    id: orderAsJson.id,
    purchaseDate: parseDate(orderAsJson.purchaseDate),
    agreementNumber: orderAsJson.agreementNumber,
    orderedBy: orderAsJson.orderedBy,
    mediaType: parseMediaType(orderAsJson.mediaType),
    ticketCount: orderAsJson.ticketCount,
    totalPrice: orderAsJson.totalPrice,
    invoiceReference: orderAsJson.invoiceReference,
    personalReference: orderAsJson.personalReference,
    productTemplateId: orderAsJson.productTemplateId,
    zoneType: parseZoneType(orderAsJson.zoneType),
    zoneFrom: orderAsJson.zoneFrom,
    zoneTo: orderAsJson.zoneTo || '',
    zoneVia: orderAsJson.zoneVia || '',
    nrOfZones: orderAsJson.nrOfZones || 1,
    failedTicketCount: orderAsJson.failedTicketCount,
    requestedTicketCount: orderAsJson.requestedTicketCount,
    zones: orderAsJson.zones,
  };
}

export const validateOrder = (order: OrderJson, path: string): void => {
  validation.validate(order, `${path}`, validation.isObject);
  validation.validate(order.id, `${path}.id`, validation.isNonEmptyString);
  validation.validate(order.purchaseDate, `${path}.purchaseDate`, validation.isParsableDate);
  validation.validate(order.agreementNumber, `${path}.agreementNumber`, validation.isNonEmptyString);
  validation.validate(order.orderedBy, `${path}.orderedBy`, validation.isNonEmptyString);
  validation.validate(order.mediaType, `${path}.mediaType`, validation.isStringOfValue('TRAVELCARD', 'MOBILETICKET'));
  validation.validate(order.ticketCount, `${path}.ticketCount`, validation.isNonNegativeInteger);
  validation.validate(order.totalPrice, `${path}.totalPrice`, validation.isNonNegativeMoney);
  validation.validate(order.invoiceReference, `${path}.invoiceReference`, validation.isString);
  validation.validate(order.personalReference, `${path}.personalReference`, validation.isString);
  validation.validate(order.productTemplateId, `${path}.productTemplateId`, validation.isPositiveInteger);
  validation.validate(order.zoneType, `${path}.zoneType`, validation.isStringOfValue('ZoneToZone', 'Radius'));
  validation.validate(order.zoneFrom, `${path}.zoneFrom`, validation.isNonEmptyString);
  validation.validate(order.zoneTo, `${path}.zoneTo`, validation.isStringOrNull);
  validation.validate(order.zoneVia, `${path}.zoneVia`, validation.isStringOrNull);
  validation.validate(order.nrOfZones, `${path}.nrOfZones`, validation.isNonNegativeIntegerOrNull);
  validation.validate(order.zones, `${path}.zones`, validation.isNonEmptyArray);
  validation.validate(order.failedTicketCount, `${path}.failedTicketCount`, validation.isNonNegativeInteger);
  validation.validate(order.requestedTicketCount, `${path}.requestedTicketCount`, validation.isNonNegativeInteger);
};
