import moment from 'moment';

type ValidationError = string;
type ValidationResult = ValidationError | true;
type Validator = (a: unknown) => ValidationResult;

function createErrorMessage(path: string, validationErrors: ValidationError[], value: unknown): string {
  const expected = validationErrors.join(', ');
  return `${path} is invalid, expected: "${expected}", recieved: type="${typeof value}", value="${value}"`;
}

function createInputErrorMessage(name: string, validationErrors: ValidationError[], value: unknown): string {
  const expected = validationErrors.join(', ');
  return `input parameter(${name}) is invalid, expected: "${expected}", recieved: type="${typeof value}", value="${value}"`;
}

export function validateInputParameter(value: unknown, name: string, ...validators: Validator[]): void {
  const validationErrors: ValidationError[] = [];

  validators.forEach((x) => {
    const validationResult = x(value);
    if (validationResult !== true) {
      validationErrors.push(validationResult);
    }
  });

  if (validationErrors.length > 0) {
    throw new Error(createInputErrorMessage(name, validationErrors, value));
  }
}

export function validate(value: unknown, path: string, ...validators: Validator[]): void {
  const validationErrors: ValidationError[] = [];

  validators.forEach((validator) => {
    const validationResult = validator(value);
    if (validationResult !== true) {
      validationErrors.push(validationResult);
    }
  });

  if (validationErrors.length > 0) {
    throw new Error(createErrorMessage(path, validationErrors, value));
  }
}

export const isString: Validator = (value: unknown) => {
  const valid = typeof value === 'string';
  if (valid) { return true; }
  return 'string';
};

export const isStringOrNull: Validator = (value: unknown) => {
  const valid = (value === null || value === undefined) || typeof value === 'string';
  if (valid) { return true; }
  return 'string or null';
};

export const isNonEmptyString: Validator = (value) => {
  const valid = value && typeof value === 'string';
  if (valid) { return true; }
  return 'non-empty string';
};

export const isNonEmptyStringOrNull: Validator = (value: unknown) => {
  const valid = (value === null || value === undefined) || (value && typeof value === 'string');
  if (valid) { return true; }
  return 'non-empty string or null';
};

export const isStringPhoneCountryCode: Validator = (value: unknown) => {
  const valid = value && typeof value === 'string' && value.indexOf('+') === 0;
  if (valid) { return true; }
  return 'phoneCountryCode';
};

export const isStringOfValue = (...validValues: string[]): Validator => (value: unknown): ValidationResult => {
  const valid = value && typeof value === 'string' && validValues.indexOf(value) !== -1;
  if (valid) { return true; }
  return `oneOf(${validValues.join(', ')})`;
};

export const isParsablePhoneNumber: Validator = (value: unknown) => {
  if (!value || typeof (value) !== 'string') {
    return 'phonenumber';
  }

  const normalizedString = value.replace(/\s/g, '');
  const onlyDigits = /^[0-9]*$/;
  const valid = onlyDigits.test(normalizedString) && normalizedString.length > 4 && normalizedString.length < 30;
  if (valid) { return true; }
  return 'phonenumber';
};

export const isPositiveInteger: Validator = (value: unknown) => {
  const valid = typeof value === 'number' && value > 0 && Math.ceil(value) === value;
  if (valid) { return true; }
  return 'positive integer';
};

export const isNonNegativeInteger: Validator = (value: unknown) => {
  const valid = typeof value === 'number' && value >= 0 && Math.ceil(value) === value;
  if (valid) { return true; }
  return 'non-negative integer';
};

export const isNonNegativeIntegerOrNull: Validator = (value: unknown) => {
  const valid = (value === null || value === undefined) || (typeof value === 'number' && value >= 0 && Math.ceil(value) === value);
  if (valid) { return true; }
  return 'non-negative integer or null';
};

export const isPositiveIntegerOrNull: Validator = (value: unknown) => {
  const valid = value === null || (typeof value === 'number' && value > 0 && Math.ceil(value) === value);
  if (valid) { return true; }
  return 'positive integer or null';
};

export const isPositiveMoney: Validator = (value: unknown) => {
  const regexForMaxToDecimals = /^\d*\.?\d{0,2}$/g;
  const valid = typeof value === 'number' && value > 0 && regexForMaxToDecimals.test(value.toString());
  if (valid) { return true; }
  return 'positive money';
};

export const isNonNegativeMoney: Validator = (value: unknown) => {
  const regexForMaxToDecimals = /^\d*\.?\d{0,2}$/g;
  const valid = typeof value === 'number' && value >= 0 && regexForMaxToDecimals.test(value.toString());
  if (valid) { return true; }
  return 'non-negative money';
};

export const isArray: Validator = (value: unknown) => {
  const valid = value && Array.isArray(value);
  if (valid) { return true; }
  return 'array';
};

export const isNonEmptyArray: Validator = (value: unknown) => {
  const valid = value && Array.isArray(value) && value.length > 0;
  if (valid) { return true; }
  return 'non-empty array';
};

export const isObject: Validator = (value: unknown) => {
  const valid = value && typeof value === 'object';
  if (valid) { return true; }
  return 'object';
};

export const isParsableDate: Validator = (value: unknown) => {
  const valid = value && typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid();
  if (valid) { return true; }
  return 'parsable date';
};

export const isBoolean: Validator = (value: unknown) => {
  const valid = typeof value === 'boolean';
  if (valid) { return true; }
  return 'boolean';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasOwnProperty<X extends Record<string, any>, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const isLocaleString: Validator = (value: unknown) => {
  const valid = value && typeof value === 'object' && hasOwnProperty(value, 'nb') && typeof value.nb === 'string';
  if (valid) { return true; }
  return 'locale string object {nb: \'foo\' }';
};
