/* eslint-disable max-len */
import React from 'react';
import { Icon } from '@ruter-ds/rds-components';

const SpeedBoatIcon: React.FC = () => (
  <Icon>
    <svg width="34" height="11" viewBox="0 0 34 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2003_532)">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2118 5.8343H-0.000198364C-0.000198364 9.9493 7.5238 10.3173 7.5238 10.3173H26.8348C26.9378 10.3173 27.0328 10.2663 27.0868 10.1793C27.1418 10.0943 27.1488 9.9853 27.1068 9.8933L25.2118 5.8343Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.3251 2.8451H9.8421L5.0821 4.7871H22.2211L21.3251 2.8451Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.3564 0.000396729H16.9174L14.8304 1.7994H19.9114L20.3564 0.000396729Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.943 4.7872H28.055V4.3412H23.943V4.7872Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.959 4.7872H30.371V4.3412H28.959V4.7872Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M31.16 4.7872H31.81V4.3412H31.16V4.7872Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M23.067 3.2902H27.179V2.8452H23.067V3.2902Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.081 3.2902H29.495V2.8452H28.081V3.2902Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.283 3.2902H30.934V2.8452H30.283V3.2902Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M27.012 6.2802H31.123V5.8342H27.012V6.2802Z" fill="#333941" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.026 6.2802H33.44V5.8342H32.026V6.2802Z" fill="#333941" />
      </g>
      <defs>
        <clipPath id="clip0_2003_532">
          <rect width="34" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>

  </Icon>
);

export default SpeedBoatIcon;
