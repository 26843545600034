export interface PriceForNumberOfZonesJson {
  nrOfZones: number;
  priceExclVAT: number;
  priceInclVAT: number;
  vat: number;
}

export interface PriceForNumberOfZones {
  nrOfZones: number;
  priceExclVAT: number;
  priceInclVAT: number;
  vat: number;
}

export const mapPriceForNumberOfZones = (json: PriceForNumberOfZonesJson): PriceForNumberOfZones => ({
  nrOfZones: json.nrOfZones,
  priceInclVAT: json.priceInclVAT,
  priceExclVAT: json.priceExclVAT,
  vat: json.vat,
});
