import {
  Button, FormGroup, Label, Select, Stepper,
} from '@ruter-ds/rds-components';
import React, { FormEvent, useEffect } from 'react';
import { ProductTemplate } from '../types/productTemplate';
import { SelectedRadiusZone } from '../types/selectedZones';
import { getZoneById, RadiusZone } from '../types/zone';
import ComponentPropTypes from './componentPropTypes';
import './ZoneRadius.scss';

export interface SelectRadiuzZoneProps {
  type: ComponentPropTypes.SelectRadiusZone
  selectedProductTemplate: ProductTemplate;
  zones: RadiusZone[];
  selectedZones: SelectedRadiusZone;
  selectZones: (zoneFrom: RadiusZone, zoneCount: number) => void;
  confirmZoneSelection: () => void;
}

const ZoneRadius: React.FC<SelectRadiuzZoneProps> = ({
  selectedProductTemplate, zones, selectedZones, selectZones, confirmZoneSelection,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onZoneForChange = (event: FormEvent<HTMLInputElement>): void => {
    const zoneFromId = event.currentTarget.value;
    const newZoneFrom = getZoneById(zones, zoneFromId);
    let newZoneCount = selectedZones.zoneCount;

    if (newZoneCount > newZoneFrom.maxZones) {
      newZoneCount = newZoneFrom.maxZones;
    }

    selectZones(newZoneFrom, newZoneCount);
  };

  const increaseZoneCount = (): void => {
    selectZones(selectedZones.zoneFrom, selectedZones.zoneCount + 1);
  };

  const decreaseZoneCount = (): void => {
    selectZones(selectedZones.zoneFrom, selectedZones.zoneCount - 1);
  };

  const maxZoneCount = Math.min(selectedProductTemplate.nrOfZonesGetAll, selectedZones.zoneFrom.maxZones);
  const showNextButton = !selectedZones.userConfirmed;

  return (
    <div className="section">
      <h2>Velg reisestrekning</h2>
      <div className="zone-radius" data-test-id="radius-zone-selector">
        <FormGroup className="for-zone">
          <Label labelText="For sone" htmlFor="for-zone" />
          <Select id="for-zone" value={selectedZones.zoneFrom.id} onChange={onZoneForChange}>
            {zones.map((x) => <option key={x.id} value={x.id}>{x.name}</option>)}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label labelText="Totalt antall soner" htmlFor="stepper" />
          <div className="stepper">
            <Stepper min={1} max={maxZoneCount} value={selectedZones.zoneCount.toString()} roundButtons size="small" label="" increaseValue={increaseZoneCount} decreaseValue={decreaseZoneCount} />
          </div>
        </FormGroup>
        {showNextButton && (
        <Button text="Gå videre" className="next-button" onClick={(): void => confirmZoneSelection()} data-test-id="zone-selector-button" />
        )}
      </div>
    </div>
  );
};

export default ZoneRadius;
