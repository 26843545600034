import { LocaleText, LocaleTextJson, mapLocaleText } from './localeText';
import { mapProduct, Product, ProductJson } from './product';
import { parseTicketType, TicketType } from './ticketType';
import * as validation from '../apiClient/typeValidations';
import { parseZoneType, ZoneType } from './zoneType';

export interface ProductTemplateJson {
  id: number,
  nrOfDaysValid: number | null,
  name: LocaleTextJson,
  nrOfZonesGetAll: number,
  ticketType: string,
  zoneType: string,
  products: ProductJson[],
}

export interface ProductTemplate {
  id: number;
  nrOfDaysValid: number | null;
  name: LocaleText;
  nrOfZonesGetAll: number;
  ticketType: TicketType;
  zoneType: ZoneType;
  products: Product[];
}

export const mapProductTemplate = (json: ProductTemplateJson): ProductTemplate => ({
  id: json.id,
  nrOfDaysValid: json.nrOfDaysValid,
  name: mapLocaleText(json.name),
  nrOfZonesGetAll: json.nrOfZonesGetAll,
  ticketType: parseTicketType(json.ticketType),
  zoneType: parseZoneType(json.zoneType),
  products: json.products.map(mapProduct),
});

export const validateProductTemplate = (productTemplate: ProductTemplateJson, tIndex: number, assertPriceForEveryZone: boolean): void => {
  validation.validate(productTemplate.id, `response[${tIndex}].id`, validation.isPositiveInteger);
  validation.validate(productTemplate.nrOfDaysValid, `response[${tIndex}].nrOfDaysValid`, validation.isPositiveIntegerOrNull);
  validation.validate(productTemplate.name, `response[${tIndex}].name`, validation.isLocaleString);
  validation.validate(productTemplate.nrOfZonesGetAll, `response[${tIndex}].nrOfZonesGetAll`, validation.isPositiveInteger);
  validation.validate(productTemplate.ticketType, `response[${tIndex}].ticketType`, validation.isStringOfValue(TicketType.MultipleJourneyTicket, TicketType.SingleJourneyTicket));
  validation.validate(productTemplate.zoneType, `response[${tIndex}].zoneType`, validation.isStringOfValue('Radius', 'ZoneToZone'));
  validation.validate(productTemplate.products, `response[${tIndex}].products`, validation.isNonEmptyArray);

  productTemplate.products.forEach((product, pIndex) => {
    validation.validate(product.id, `response[${tIndex}].products[${pIndex}].id`, validation.isPositiveInteger);
    validation.validate(product.profileDescription, `response[${tIndex}].products[${pIndex}].profileDescription`, validation.isLocaleString);
    validation.validate(product.profileId, `response[${tIndex}].products[${pIndex}].profileId`, validation.isPositiveInteger);
    validation.validate(product.profileName, `response[${tIndex}].products[${pIndex}].profileName`, validation.isLocaleString);
    validation.validate(product.prices, `response[${tIndex}].products[${pIndex}].prices`, validation.isNonEmptyArray);

    product.prices.forEach((price, priceIndex) => {
      validation.validate(price, `response[${tIndex}].products[${pIndex}].prices[${priceIndex}]`, validation.isObject);
      validation.validate(price.nrOfZones, `response[${tIndex}].products[${pIndex}].prices[${priceIndex}].nrOfZones`, validation.isPositiveInteger);
      validation.validate(price.priceInclVAT, `response[${tIndex}].products[${pIndex}].prices[${priceIndex}].priceInclVAT`, validation.isPositiveMoney);
      validation.validate(price.priceExclVAT, `response[${tIndex}].products[${pIndex}].prices[${priceIndex}].priceExclVAT`, validation.isPositiveMoney);
      validation.validate(price.vat, `response[${tIndex}].products[${pIndex}].prices[${priceIndex}].vat`, validation.isPositiveMoney);
    });

    const magicNumberForNoAllZonesProduct = 99;

    let { nrOfZonesGetAll } = productTemplate;
    if (nrOfZonesGetAll >= magicNumberForNoAllZonesProduct) {
      nrOfZonesGetAll = Math.max(...product.prices.map((x) => x.nrOfZones));
    }

    if (assertPriceForEveryZone) {
      for (let i = 1; i <= nrOfZonesGetAll; i += 1) {
        const price = product.prices.find((x) => x.nrOfZones === i);
        if (!price) {
          throw new Error(`productTemplate expects ${nrOfZonesGetAll} prices according to the nrOfZonesGetAll property, missing one or more prices`);
        }
      }
    }
  });
};
