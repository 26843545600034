import React from 'react';
import ComponentPropTypes from './componentPropTypes';

export interface HeaderProps {
  type: ComponentPropTypes.Header;
  text: string;
}

const Header: React.FC<HeaderProps> = ({ text }) => (
  <h1>{text}</h1>
);

export default Header;
