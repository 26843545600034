import { BlockLink, BlockLinkList } from '@ruter-ds/rds-components';
import React from 'react';
import { Profile } from '../types/profile';
import ComponentPropTypes from './componentPropTypes';
import './ProfileSelector.scss';

export interface ProfileSelectorProps {
  type: ComponentPropTypes.ProfileSelector,
  profiles: Profile[];
  selectedProfile: Profile | undefined;
  selectProfile: (profile: Profile) => void;
  selectedLanguage: 'nb' | 'en';
}

const ProfileSelector: React.FC<ProfileSelectorProps> = ({
  profiles, selectedProfile, selectedLanguage, selectProfile,
}) => {
  const renderType = (profile: Profile): JSX.Element => {
    const selected = selectedProfile && profile.id === selectedProfile.id;

    return (
      <BlockLink
        className={`profile ${selected ? 'selected' : ''}`}
        data-test-id={`profileId=${profile.id}`}
        onClick={(): void => selectProfile(profile)}
        key={profile.id}
      >

        {profile.name[selectedLanguage]}
      </BlockLink>
    );
  };

  return (
    <div className="section">
      <h2>Velg reisende</h2>
      <BlockLinkList data-test-id="profile-selector">
        {profiles.map(renderType)}
      </BlockLinkList>
    </div>
  );
};

export default ProfileSelector;
