import { ProductCategoryId } from '../types/productCategory';
import { ProductTemplate } from '../types/productTemplate';
import { SelectedZoneToZone, SelectedZoneToZoneCalculationError } from '../types/selectedZones';
import { NO_ZONE } from '../types/zone';

const getProductTemplateById = (productTemplates: ProductTemplate[], id: number): ProductTemplate | undefined => productTemplates.find((x) => x.id === id);

const productTemplatesWhiteList = (productTemplates: ProductTemplate[], ids: number[]): ProductTemplate[] => {
  const outpout: ProductTemplate[] = [];

  ids.forEach((id) => {
    const productTemplate = getProductTemplateById(productTemplates, id);
    if (productTemplate) {
      outpout.push(productTemplate);
    }
  });

  if (!outpout.length) {
    throw new Error(`productTemplatesWhitelist returned empty result for ids: ${ids.join(',')}`);
  }
  return outpout;
};

const productTemplatesBlackList = (productTemplates: ProductTemplate[], ids: number[]): ProductTemplate[] => {
  const outpout = productTemplates.filter((x) => !ids.some((y) => y === x.id));

  if (!outpout.length) {
    throw new Error(`productTemplatesBlacklist returned empty result for ids: ${ids.join(',')}`);
  }

  return outpout;
};
export default {
  filterByProductCategoryId: (productTemplates: ProductTemplate[], productCategoryId: ProductCategoryId): ProductTemplate[] => {
    switch (productCategoryId) {
      case 'single-ticket-bus': return productTemplatesWhiteList(productTemplates, [5000, 1411]);
      case 'period-ticket-bus': return productTemplatesWhiteList(productTemplates, [5010, 5011, 5012, 5013, 5016, 5017]);
      case 'youth-total': return productTemplatesWhiteList(productTemplates, [5015]);
      case 'speed-boat': return productTemplatesWhiteList(productTemplates, [5020, 5021]);
      case 'ferry': return productTemplatesWhiteList(productTemplates, [1411, 5030, 5031, 5035]);
      default: throw new Error(`no productTemplateMapping for productCategoryId=${productCategoryId}`);
    }
  },
  filterByZoneToZone: (productTemplates: ProductTemplate[], selectedZones: SelectedZoneToZone | SelectedZoneToZoneCalculationError): ProductTemplate[] => {
    // Tromsø
    if (selectedZones.zoneFrom.id === 'TRO:TariffZone:54010' && selectedZones.zoneTo === NO_ZONE) {
      return productTemplatesBlackList(productTemplates, [5013, 5012]);
    }
    return productTemplatesBlackList(productTemplates, [1411, 5017, 5016]);
  },
  filterByProfile: (productTemplates: ProductTemplate[], profileId: number)
    : ProductTemplate[] => productTemplates.filter((productTemplate) => productTemplate.products.some((x) => x.profileId === profileId)),
  getProductTemplateById,
};
