import {
  Button, Input, Message, TextArea, TextLink,
} from '@ruter-ds/rds-components';
import { DeviationExclamationCircleIcon, ExclamationCircleIcon } from '@ruter-ds/rds-icons';
import React, { useEffect, useState } from 'react';
import { Product } from '../types/product';
import { ProductTemplate } from '../types/productTemplate';
import { Recipient } from '../types/Recipient';
import { RecipientBatch } from '../types/RecipientBatch';
import { SelectedZones } from '../types/selectedZones';
import { NO_ZONE } from '../types/zone';
import numberFormat from '../utils/numberFormatting';
import {
  calculateTotalPrice, calculateTotalVat, findPrice, getTicketCount,
} from '../utils/priceCalculator';
import ComponentPropTypes from './componentPropTypes';
import './Summary.scss';

export interface SummaryProps {
  type: ComponentPropTypes.SummaryAndOrder;
  selectedProductTemplate: ProductTemplate;
  selectedProduct: Product;
  selectedZones: SelectedZones;
  recipients: Recipient[];
  recipientBatches: RecipientBatch[];
  selectedLanguage: 'nb' | 'en';
  personalReference: string;
  invoiceReference: string;
  orderFailed: boolean;
  orderInProgress: boolean;
  errorLink: string;
  setPersonalReference: (value: string) => void;
  setInvoiceReference: (value: string) => void;
  order: () => void;
}

const Summary: React.FC<SummaryProps> = ({
  selectedProductTemplate,
  selectedProduct,
  selectedZones,
  recipients,
  recipientBatches,
  selectedLanguage,
  personalReference,
  invoiceReference,
  orderFailed,
  orderInProgress,
  errorLink,
  setPersonalReference,
  setInvoiceReference,
  order,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTicketPrice = (): string => {
    const price = findPrice(selectedProductTemplate, selectedProduct, selectedZones.zoneCount);
    return `${numberFormat(price)} kr/stk.`;
  };

  const getZone = (): string => {
    if (selectedZones.zoneCount >= selectedProductTemplate.nrOfZonesGetAll) {
      return 'Alle soner';
    }

    if (selectedZones.zoneCount === 1) {
      return `Sone ${selectedZones.zoneFrom.name}`;
    }

    if (selectedZones.zoneType === 'Radius') {
      return `${selectedZones.zoneCount} soner fra sone ${selectedZones.zoneFrom.name}`;
    }

    if (selectedZones.zoneTo !== NO_ZONE) {
      return `Sone ${selectedZones.zoneFrom.name} - ${selectedZones.zoneTo.name}`;
    }

    throw new Error(`could not create string for zone=${JSON.stringify(selectedZones)}`);
  };

  const calculateNumberOfTickets = (): number => getTicketCount(recipients, recipientBatches);

  const formatTotalPrice = (): string => {
    const totalPrice = calculateTotalPrice(selectedProductTemplate, selectedProduct, selectedZones.zoneCount, recipients, recipientBatches);
    return `${numberFormat(totalPrice)} kr`;
  };

  const calculateVat = (): string => {
    const totalVat = calculateTotalVat(selectedProductTemplate, selectedProduct, selectedZones.zoneCount, recipients, recipientBatches);
    return `${numberFormat(totalVat, 2)} kr`;
  };

  const toggleDetails = (): void => {
    setShowDetails(!showDetails);
  };

  const renderBatchRow = (recipientBatch: RecipientBatch): JSX.Element => {
    const ticketCount = getTicketCount([], [recipientBatch]);
    const totalPrice = calculateTotalPrice(selectedProductTemplate, selectedProduct, selectedZones.zoneCount, [], [recipientBatch]);

    return (
      <div className="details-row" key={recipientBatch.id}>
        <div className="phone">
          <span className="label">Fil:</span>
          <span className="value" data-test-id="details-phone">{recipientBatch.name}</span>
        </div>
        <div className="count" data-test-id="details-count">{ticketCount}</div>
        <div className="price">
          <span data-test-id="details-price">{totalPrice}</span>
          <span> kr</span>
        </div>
      </div>
    );
  };

  const renderDetailsRow = (recipient: Recipient): JSX.Element => {
    const price = findPrice(selectedProductTemplate, selectedProduct, selectedZones.zoneCount);
    const rowPrice = numberFormat(price * recipient.count);

    return (
      <div className="details-row" key={recipient.phoneNumber}>
        <div className="phone">
          <span className="label">Tlf.:</span>
          <span className="value" data-test-id="details-phone">{recipient.phoneNumber}</span>
        </div>
        <div className="count" data-test-id="details-count">{recipient.count}</div>
        <div className="price">
          <span data-test-id="details-price">{rowPrice}</span>
          <span> kr</span>
        </div>
      </div>
    );
  };

  const toggleDetailsText = showDetails ? 'Skjul detaljer' : 'Vis detaljer';

  const renderSummary = (): JSX.Element => (
    <>
      <div className="summary-section" data-test-id="summary-section">
        <div className="sum-row">
          <div className="ticket-description">
            <div className="row1">
              <span data-test-id="product-template-name">{selectedProductTemplate.name[selectedLanguage]}</span>
              <span>, </span>
              <span data-test-id="product-name">{selectedProduct.profileName[selectedLanguage].toLowerCase()}</span>
            </div>
            <div className="row2">
              <span data-test-id="zone-info">{getZone()}</span>
            </div>
            <div>
              <span data-test-id="ticket-price">{getTicketPrice()}</span>
            </div>
          </div>
          <div className="ticket-count" data-test-id="ticket-count">{calculateNumberOfTickets()}</div>
          <div className="ticket-price" data-test-id="total-price">{formatTotalPrice()}</div>
        </div>
        {showDetails && (
        <div className="details-section" data-test-id="details-section">
          {recipientBatches.map(renderBatchRow)}
          {recipients.map(renderDetailsRow)}
          <div className="details-sum-row">
            <div className="label">Total pris</div>
            <div className="price" data-test-id="details-sum-price">
              {formatTotalPrice()}
            </div>
          </div>
        </div>
        )}
        <button type="button" className="show-show-all-tickets" data-test-id="toggle-details" onClick={toggleDetails}>
          {toggleDetailsText}
        </button>
        <div className="vat-row">
          <div className="description">Herav mva</div>
          <div className="price" data-test-id="total-vat">{calculateVat()}</div>
        </div>
      </div>
      <div className="bottom-border" />
    </>
  );

  return (
    <>
      <div data-test-id="summary-and-order-section">
        <div className="section">
          <h2>Billetter</h2>
          {renderSummary()}
        </div>
        <div className="section">
          <h2>Fakturareferanse</h2>
          <div className="invoice-reference-section">
            <Input name="invoice-reference" value={invoiceReference} onChange={(event: React.FormEvent<HTMLInputElement>): void => setInvoiceReference(event.currentTarget.value.toUpperCase())} />
          </div>
        </div>
        <div className="section">
          <h2>Kommentar</h2>
          <TextArea
            size="small"
            name="personal-reference"
            value={personalReference}
            onChange={(event: React.FormEvent<HTMLInputElement>): void => setPersonalReference(event.currentTarget.value)}
          />
        </div>
        <div className="section">
          <h2>Praktisk Info</h2>
          <div className="practical-info-section">
            <Message skin="info" icon={<DeviationExclamationCircleIcon />}>
              Mottaker bestemmer selv når billetten skal startes. Men etter at billetten er hentet ned, må den benyttes (eller kanselleres) innen 7 dager.
            </Message>
          </div>
        </div>
        {orderFailed && (
        <Message
          skin="danger"
          title="Klarte ikke å bestille billetter"
          icon={<ExclamationCircleIcon />}
          className="order-error"
          data-test-id="order-error"
        >
          <p>
            Det skjedde en feil ved bestilling av billetter, prøv igjen eller&nbsp;
            <TextLink target="_blank" rel="noopener noreferrer" href={errorLink}> kontakt kundeservice</TextLink>
            &nbsp;hvis problemet vedvarer
          </p>
        </Message>
        )}
        <Button isLoading={orderInProgress} className="buy-ticket" text="Bestill billett" variant="filled" skin="success" data-test-id="order-button" onClick={order} />
      </div>
    </>
  );
};

export default Summary;
