/* eslint-disable max-len */
import React from 'react';
import { Icon } from '@ruter-ds/rds-components';

const SingleTicketBusIcon: React.FC = () => (
  <Icon>
    <svg width="46" height="17" viewBox="0 0 46 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M36.6 13.8C36.6 12.1 35.2 10.7 33.5 10.7C31.8 10.7 30.4 12.1 30.4 13.8C30.4 15.5 31.8 16.9 33.5 16.9C35.2 16.9 36.6 15.5 36.6 13.8Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2 13.8C12.2 12.1 10.8 10.7 9.1 10.7C7.4 10.7 6 12.1 6 13.8C6 15.5 7.4 16.9 9.1 16.9C10.8 16.9 12.2 15.5 12.2 13.8Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M45.1 6.5C45.1 6.7 45 6.8 44.9 6.9C44.8 7 44.6 7.1 44.5 7.1H35.3C35 7.1 34.7 6.8 34.7 6.5V3C34.7 2.8 34.8 2.7 34.9 2.6C35 2.5 35.2 2.4 35.3 2.4H44.5C44.8 2.4 45.1 2.7 45.1 3V6.5ZM33.7 6.5C33.7 6.7 33.6 6.8 33.5 6.9C33.4 7 33.2 7.1 33.1 7.1H23.9C23.6 7.1 23.3 6.8 23.3 6.5V3C23.3 2.8 23.4 2.7 23.5 2.6C23.6 2.5 23.8 2.4 23.9 2.4H33.1C33.4 2.4 33.7 2.7 33.7 3V6.5ZM22.4 6.5C22.4 6.7 22.3 6.8 22.2 6.9C22.1 7 21.9 7.1 21.8 7.1H12.5C12.2 7.1 11.9 6.8 11.9 6.5V3C11.9 2.8 12 2.7 12.1 2.6C12.2 2.5 12.4 2.4 12.5 2.4H21.8C22.1 2.4 22.4 2.7 22.4 3V6.5ZM10.9 6.5C10.9 6.7 10.8 6.8 10.7 6.9C10.6 7 10.4 7.1 10.3 7.1H2.2C2 7.1 1.9 7 1.7 6.9C1.6 6.7 1.6 6.5 1.7 6.3L2.9 2.6C2.9 2.5 3.1 2.4 3.2 2.4H10.3C10.6 2.4 10.9 2.7 10.9 3V6.5ZM45.2 0.5H2.7C2.5 0.5 2.4 0.6 2.4 0.7L0 8V13.3C0 13.5 0.1 13.6 0.2 13.7C0.3 13.8 0.5 13.9 0.6 13.9H5.1C5.1 11.7 6.9 10 9 10C11.1 10 12.9 11.7 12.9 13.9H29.4C29.4 11.7 31.2 10 33.3 10C35.5 10 37.2 11.7 37.2 13.9H45.4C45.5 13.9 45.6 13.9 45.7 13.8C45.8 13.7 45.8 13.6 45.8 13.6V1.2C45.9 0.8 45.6 0.5 45.2 0.5Z" fill="#333941" />
    </svg>
  </Icon>
);

export default SingleTicketBusIcon;
