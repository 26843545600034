import * as validation from '../apiClient/typeValidations';

export interface RecipientRequestData {
  phoneCountryCode: string;
  phone: string;
  count: number;
}

export const validateRecipientRequestData = (data: RecipientRequestData, path: string): void => {
  validation.validate(data.phoneCountryCode, `${path}phoneCountryCode`, validation.isStringPhoneCountryCode);
  validation.validate(data.phone, `${path}phone`, validation.isParsablePhoneNumber);
  validation.validate(data.count, `${path}count`, validation.isPositiveInteger);
};
