import { BlockLink, BlockLinkList } from '@ruter-ds/rds-components';
import {
  Ticket24HoursIcon, Ticket30DaysIcon, Ticket365DaysIcon, Ticket7DaysIcon, TicketIcon, TicketRufusIcon,
} from '@ruter-ds/rds-icons';
import React from 'react';
import './ProductTemplateSelector.scss';
import { ProductTemplate } from '../types/productTemplate';
import ComponentPropTypes from './componentPropTypes';
import { Product } from '../types/product';
import numberFormat from '../utils/numberFormatting';
import { findPrice } from '../utils/priceCalculator';

export interface PriceParams {
  zoneCount: number;
  profileId: number;
}

export interface ProductTemplateSelectorProps {
  type: ComponentPropTypes.ProductTemplateSelector,
  productTemplates: ProductTemplate[];
  selectedProductTemplate: ProductTemplate | undefined;
  selectedLanguage: 'nb' | 'en';
  priceParams: PriceParams | undefined;
  selectProductTemplate: (productTemplate: ProductTemplate) => void
}

const getIcon = (productTemplate: ProductTemplate): JSX.Element => {
  if (productTemplate.id === 40) {
    return <TicketRufusIcon />;
  }
  if (productTemplate.nrOfDaysValid === 365) {
    return <Ticket365DaysIcon />;
  }
  if (productTemplate.nrOfDaysValid === 30) {
    return <Ticket30DaysIcon />;
  }
  if (productTemplate.nrOfDaysValid === 7) {
    return <Ticket7DaysIcon />;
  }
  if (productTemplate.nrOfDaysValid === 1) {
    return <Ticket24HoursIcon />;
  }
  return <TicketIcon />;
};

const getProduct = (productTemplate: ProductTemplate, profileId: number): Product => {
  const output = productTemplate.products.find((x) => x.profileId === profileId);

  if (!output) {
    throw new Error(`Could not find product with profileId=${profileId} for productTemplate with id=${productTemplate.id}`);
  }

  return output;
};

const formatPrice = (price: number): string => `${numberFormat(price)},-`;

const ProductTemplateSelector: React.FC<ProductTemplateSelectorProps> = ({
  productTemplates, selectedLanguage, selectedProductTemplate, priceParams, selectProductTemplate,
}) => {
  const sortProductTemplate = (productTemplateA: ProductTemplate, productTemplateB: ProductTemplate): number => {
    const sortA = productTemplateA.name[selectedLanguage] === 'Rufusbillett' ? 999 : productTemplateA.nrOfDaysValid || 0;
    const sortB = productTemplateB.name[selectedLanguage] === 'Rufusbillett' ? 999 : productTemplateB.nrOfDaysValid || 0;

    return sortA - sortB;
  };

  const renderType = (productTemplate: ProductTemplate): JSX.Element => {
    const selected = selectedProductTemplate && productTemplate.id === selectedProductTemplate.id;

    return (
      <BlockLink
        className={`product-template ${selected ? 'selected' : ''}`}
        data-test-id={`productTemplateId=${productTemplate.id}`}
        onClick={(): void => selectProductTemplate(productTemplate)}
        icon={getIcon(productTemplate)}
        key={productTemplate.id}
      >
        <div className="item">
          <div className="title">
            {productTemplate.name[selectedLanguage]}
          </div>
          {priceParams && (
          <div className="price" data-test-id="product-template-selector-price">
            {formatPrice(findPrice(productTemplate, getProduct(productTemplate, priceParams.profileId), priceParams.zoneCount))}
          </div>
          )}
        </div>

      </BlockLink>

    );
  };

  return (
    <div className="section">
      <h2>Velg billettype</h2>
      <BlockLinkList data-test-id="product-template-selector">
        {productTemplates.sort(sortProductTemplate).map(renderType)}
      </BlockLinkList>
    </div>
  );
};

export default ProductTemplateSelector;
