/* eslint-disable max-len */
import React from 'react';
import { Icon } from '@ruter-ds/rds-components';

const FerryIcon: React.FC = () => (
  <Icon>
    <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.511 7.3065H9.46L10.597 0.439498C10.628 0.253498 10.757 0.098498 10.932 0.033498C11.107 -0.032502 11.305 0.000498012 11.451 0.119498C11.595 0.239498 11.665 0.426498 11.633 0.611498L10.511 7.3065Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.7234 4.1532H17.7524L19.1544 7.3062H13.1964L13.7234 4.1532Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5413 7.3065H21.4903L22.6273 0.439498C22.6573 0.253498 22.7853 0.098498 22.9623 0.033498C23.1383 -0.032502 23.3353 0.000498012 23.4803 0.119498C23.6253 0.239498 23.6943 0.426498 23.6633 0.611498L22.5413 7.3065Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6194 10.2855H18.1064V12.2845H14.2034C14.1804 12.2845 14.1604 12.2735 14.1504 12.2535C14.1394 12.2355 14.1394 12.2125 14.1494 12.1925L15.4054 10.4115C15.4484 10.3325 15.5304 10.2855 15.6194 10.2855Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.926 14.5316L10.266 12.8866C10.278 12.7996 10.376 12.6056 10.483 12.5006C10.586 12.3956 10.635 12.3936 10.878 12.3376C11.121 12.2816 13.208 11.9516 13.208 11.9516C13.321 11.9386 13.506 11.8436 13.696 11.6636L14.866 10.1936C14.98 9.9886 15.196 9.8616 15.484 9.8616C17.253 9.7246 20.36 9.8616 20.36 9.8616C20.533 9.8616 20.741 9.9796 20.858 10.1716L21.989 12.1316C21.989 12.1316 22.085 12.3106 22.14 12.4116C22.194 12.5116 22.358 13.3706 22.333 13.7926C22.84 13.7926 22.846 14.4686 22.846 14.4686C22.846 14.6396 22.843 14.9826 22.695 15.0696C22.507 15.1776 22.507 15.1776 22.336 15.1776H21.639C21.639 14.6996 21.384 14.2566 20.969 14.0166C20.555 13.7786 20.043 13.7786 19.628 14.0166C19.212 14.2566 18.957 14.6996 18.957 15.1776H13.74C13.74 14.6996 13.484 14.2566 13.069 14.0166C12.655 13.7786 12.143 13.7786 11.728 14.0166C11.315 14.2566 11.058 14.6996 11.058 15.1776H10.571C10.401 15.1776 10.236 15.1096 10.115 14.9886C9.994 14.8676 9.926 14.7036 9.926 14.5316ZM11.308 15.1776C11.308 14.5766 11.797 14.0876 12.399 14.0876C13.002 14.0876 13.49 14.5766 13.49 15.1776C13.49 15.7806 13.002 16.2686 12.399 16.2686C11.797 16.2686 11.308 15.7806 11.308 15.1776ZM19.208 15.1776C19.208 14.5766 19.696 14.0876 20.299 14.0876C20.9 14.0876 21.39 14.5766 21.39 15.1776C21.39 15.7806 20.9 16.2686 20.299 16.2686C19.696 16.2686 19.208 15.7806 19.208 15.1776ZM0 12.0376L2.452 17.2926H31.452C31.571 17.2926 31.683 17.2316 31.747 17.1316C31.813 17.0306 31.819 16.9036 31.769 16.7946L29.548 12.0376H24.498V10.8106H26.045L24.995 8.5336H6.306L5.955 10.8106H7.404V12.0376H0Z" fill="#333941" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2473 10.4417L21.1703 12.2847H18.6723V10.2857H19.9663C20.0813 10.2857 20.1863 10.3437 20.2473 10.4417Z" fill="#333941" />
    </svg>
  </Icon>
);

export default FerryIcon;
