import {
  Button, FormGroup, Label, Message, Select, Spinner,
} from '@ruter-ds/rds-components';
import { ExclamationCircleIcon } from '@ruter-ds/rds-icons';
import React, { FormEvent, useEffect } from 'react';
import { SelectedZoneToZone, SelectedZoneToZoneCalculationError } from '../types/selectedZones';
import {
  getZoneById, NO_ZONE, Zone, ZoneTo,
} from '../types/zone';
import ComponentPropTypes from './componentPropTypes';
import './ZoneToZone.scss';

export interface SelectZoneToZoneProps {
  type: ComponentPropTypes.SelectZoneToZone;
  zones: Zone[];
  selectedZones: SelectedZoneToZone | SelectedZoneToZoneCalculationError;
  calculatingDistance: boolean;
  nrOfZonesGetAll: number | undefined;

  selectZones: (zoneFrom: Zone, zoneTo: ZoneTo) => Promise<void>;
  confirmZoneSelection: () => void;
}

const ZoneToZone: React.FC<SelectZoneToZoneProps> = ({
  zones, selectedZones, calculatingDistance, nrOfZonesGetAll, selectZones, confirmZoneSelection,
}) => {
  const calculationError = selectedZones.zoneType === 'CalculationError';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onZoneFromChange = async (event: FormEvent<HTMLInputElement>): Promise<void> => {
    const newZoneFrom = getZoneById(zones, event.currentTarget.value);
    const newZoneTo = NO_ZONE;
    selectZones(newZoneFrom, newZoneTo);
  };

  const onZoneToChange = async (event: FormEvent<HTMLInputElement>): Promise<void> => {
    const zoneToId = event.currentTarget.value;
    const newZoneTo: ZoneTo = zoneToId === NO_ZONE ? NO_ZONE : getZoneById(zones, zoneToId);
    selectZones(selectedZones.zoneFrom, newZoneTo);
  };

  const onNextButtonClick = (): void => {
    confirmZoneSelection();
  };

  const getZoneCountString = (): string => {
    if (selectedZones.zoneType === 'CalculationError') {
      return '';
    }

    if (nrOfZonesGetAll && selectedZones.zoneCount >= nrOfZonesGetAll) {
      return 'Alle';
    }

    return selectedZones.zoneCount.toString();
  };

  const toZonesOptions = [{ id: NO_ZONE, name: '' }, ...zones.filter((x) => x.id !== selectedZones.zoneFrom.id)];
  const showNextButton = !selectedZones.userConfirmed;

  return (
    <div className="section">
      <h2>Velg reisestrekning</h2>
      <div className="zone-to-zone" data-test-id="zone-to-zone-selector">
        <FormGroup>
          <Label labelText="Sone" htmlFor="from-zone" />
          <Select id="from-zone" className="zone-select" data-test-id="zone-from" onChange={onZoneFromChange} value={selectedZones.zoneFrom.id}>
            {zones.map((x) => <option key={x.id} value={x.id}>{x.name}</option>)}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label labelText="Til annen sone (valgfritt)" htmlFor="to-zone" />
          <Select id="to-zone" className="zone-select" onChange={onZoneToChange} value={selectedZones.zoneTo === NO_ZONE ? NO_ZONE : selectedZones.zoneTo.id}>
            {toZonesOptions.map((x) => <option key={x.id} value={x.id}>{x.name}</option>)}
          </Select>
        </FormGroup>
        <FormGroup>
          {!calculationError && !calculatingDistance && (
          <div>
            <span>Antall soner: </span>
            <span data-test-id="zone-count">{getZoneCountString()}</span>
          </div>
          )}
          {!calculationError && calculatingDistance && (
          <div className="calculating-distance-loading" data-test-id="calculating-distance-loading">
            <span className="label">Antall soner: </span>
            <Spinner />
          </div>
          )}
          {calculationError && !calculatingDistance && (
          <Message data-test-id="zone-calculate-error" skin="danger" title="Feil: beregne soner" icon={<ExclamationCircleIcon />}>
            <button
              data-test-id="retry-calculate-zone-count"
              className="retry-calculate-zone-count"
              onClick={(): Promise<void> => selectZones(selectedZones.zoneFrom, selectedZones.zoneTo)}
              type="button"
            >
              Trykk her for å prøve på nytt

            </button>
          </Message>
          )}

        </FormGroup>
        {showNextButton && (
        <Button text="Gå videre" className="next-button" onClick={onNextButtonClick} data-test-id="zone-selector-button" disabled={calculatingDistance} />
        )}
      </div>
    </div>
  );
};

export default ZoneToZone;
