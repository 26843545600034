import React from 'react';
import useRuterStateHandler from '../stateHandler/ruterStateHandler';
import useTromsStateHandler from '../stateHandler/tromsStateHandler';
import { AuthenticationService } from '../types/authenticationService';
import Tenant from '../types/tenant';
import BackButton from './BackButton';
import { ComponentProps } from './componentProps';
import ComponentPropTypes from './componentPropTypes';
import Header from './Header';
import Loading from './Loading';
import NoZonesRequried from './NoZonesRequried';
import OrderConfirmed from './OrderConfirmed';
import './OrderTicketWizard.scss';
import ProductCategorySelector from './ProductCategory';
import ProductSelector from './ProductSelector';
import ProductTemplateSelector from './ProductTemplateSelector';
import ProfileSelector from './ProfileSelector';
import Recipients from './Recipients';
import Summary from './Summary';
import ZoneRadius from './ZoneRadius';
import ZoneToZone from './ZoneToZone';

export interface OrderTicketWizardProps {
  agreementNumber: string;
  errorLink: string;
  header?: string;
  authService: AuthenticationService;
  tenant: Tenant;
  apiBasePath?: string;
}

const OrderTicketWizard: React.FC<OrderTicketWizardProps> = ({
  agreementNumber, errorLink, header = 'Bestill billetter', authService, tenant, apiBasePath = '/ticket-api',
}) => {
  if (!agreementNumber || typeof agreementNumber !== 'string') {
    throw new Error('missing agreementNumber(string) in props');
  }

  if (!errorLink || typeof errorLink !== 'string') {
    throw new Error('missing errorLink(string) in props');
  }

  const componentPropsList: ComponentProps[] = [];

  switch (tenant) {
    case Tenant.Ruter: {
      const props = useRuterStateHandler(authService, header, errorLink, agreementNumber, apiBasePath);
      componentPropsList.push(...props);
      break;
    }
    case Tenant.Troms: {
      const props = useTromsStateHandler(authService, header, errorLink, agreementNumber, apiBasePath);
      componentPropsList.push(...props);
      break;
    }
    default: {
      throw new Error(`Not configured for tenant: ${tenant}`);
    }
  }

  const mapPropsToComponet = (componentProps: ComponentProps): JSX.Element => {
    switch (componentProps.type) {
      case ComponentPropTypes.Loading: return (<Loading key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.Header: return (<Header key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.BackButton: return <BackButton key={componentProps.type} {...componentProps} />;
      case ComponentPropTypes.ProductTemplateSelector: return <ProductTemplateSelector key={componentProps.type} {...componentProps} />;
      case ComponentPropTypes.SelectZoneToZone: return (<ZoneToZone key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.SelectRadiusZone: return (<ZoneRadius key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.ProductSelector: return (<ProductSelector key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.AddRecipients: return (<Recipients key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.SummaryAndOrder: return (<Summary key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.OrderConfirmed: return (<OrderConfirmed key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.ProductCategory: return (<ProductCategorySelector key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.NoZonesRequired: return (<NoZonesRequried key={componentProps.type} {...componentProps} />);
      case ComponentPropTypes.ProfileSelector: return (<ProfileSelector key={componentProps.type} {...componentProps} />);
      default: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error(`No component configured for StateType=${(componentProps as any).type}`);
      }
    }
  };

  return (
    <div className="components-orderticketwizard" data-test-id="order-ticket-wizard">
      {componentPropsList.map(mapPropsToComponet)}
    </div>
  );
};

export default OrderTicketWizard;
