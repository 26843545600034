import React from 'react';
import { Spinner } from '@ruter-ds/rds-components';
import ComponentPropTypes from './componentPropTypes';

export interface LoadingProps {
  type: ComponentPropTypes.Loading;
}

const Loading: React.FC<LoadingProps> = () => (
  <div className="loading-container">
    <Spinner size="medium" data-test-id="spinner-component" />
  </div>
);

export default Loading;
