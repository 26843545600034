import parsePhoneNumberFromString from 'libphonenumber-js/max';
import { useEffect, useState } from 'react';
import { AuthenticationService } from '..';
import TicketApiClient from '../apiClient/ticketApi/ticketApiClient';
import { ComponentProps } from '../components/componentProps';
import ComponentPropTypes from '../components/componentPropTypes';
import { getDefaultRow } from '../components/Recipients';
import { Product } from '../types/product';
import { ProductTemplate } from '../types/productTemplate';
import { RecipientBatch } from '../types/RecipientBatch';
import {
  SelectedRadiusZone, SelectedZones, SelectedZoneToZone, SelectedZoneToZoneCalculationError,
} from '../types/selectedZones';
import {
  NO_ZONE, RadiusZone, Zone, ZoneTo,
} from '../types/zone';
import Page from './page';
import SELECTED_LANGUAGE from './selectedLanguage';
import { WizardStateHandler } from './wizardStateHandler';

const useRuterStateHandler: WizardStateHandler = (authService: AuthenticationService, header: string, errorLink: string, agreementNumber: string, baseApiPath: string) => {
  const [productTemplates, setProductTemplates] = useState<ProductTemplate[] | null>(null);
  const [radiusZones, setRadiusZones] = useState<RadiusZone[] | null>(null);
  const [zones, setZones] = useState<Zone[] | null>(null);
  const [selectedProductTemplate, setSelectedProductTemplate] = useState<undefined | ProductTemplate>(undefined);
  const [currentPage, setCurrentPage] = useState(Page.First);
  const [selectedZones, setSelectedZones] = useState<null | SelectedZones | SelectedZoneToZoneCalculationError>(null);
  const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);
  const [calculatingDistance, setCalculatingDistance] = useState(false);
  const [recipients, setRecipients] = useState([getDefaultRow(1)]);
  const [recipientBatches, setRecipientBatches] = useState<RecipientBatch[]>([]);
  const [orderFailed, setOrderFailed] = useState(false);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [invoiceReference, setInvoiceReference] = useState('');
  const [personalReference, setPersonalReference] = useState('');

  const ticketApiClient = new TicketApiClient(authService, baseApiPath);
  useEffect(() => {
    const fetch = async (): Promise<void> => {
      try {
        const productsRequest = ticketApiClient.productTemplates.getAll();
        const zonesRequest = ticketApiClient.zones.getAll();

        await Promise.all([productsRequest, zonesRequest]).then((results) => {
          setProductTemplates(results[0]);
          setZones(results[1]);
          setRadiusZones(results[1].filter((x): x is RadiusZone => x.type === 'RadiusZone'));
        });
      } catch (e: unknown) {
        setZones(() => { throw e; });
      }
    };
    fetch();
  }, []);

  const order = (): void => {
    if (orderInProgress) {
      return;
    }

    setOrderInProgress(true);
    setOrderFailed(false);

    if (!selectedZones) {
      throw new Error('selectedZones must be set');
    }
    if (!selectedProduct) {
      throw new Error('selectedProduct must be set');
    }

    let orderPromise;

    const recipientsContract = [];

    recipientBatches.forEach((batch) => {
      batch.validPhoneNumbers.forEach((number) => {
        recipientsContract.push({
          phone: number.phone.replace(/\s/g, ''),
          phoneCountryCode: number.phoneCountryCode,
          count: batch.ticketCount,
        });
      });
    });

    recipientsContract.push(...recipients.filter((x) => x.phoneNumber).map((x) => {
      const parsed = parsePhoneNumberFromString(x.phoneNumber);
      if (!parsed) {
        throw new Error(`could not parse phonenumber ${x.phoneNumber}`);
      }

      return {
        phone: parsed.nationalNumber.toString(),
        phoneCountryCode: `+${parsed.countryCallingCode}`,
        count: x.count,
      };
    }));

    if (selectedZones.zoneType === 'Radius') {
      orderPromise = ticketApiClient.tickets.createRadiusTicket({
        agreementNumber,
        invoiceReference,
        personalReference,
        productId: selectedProduct.id,
        zoneFrom: selectedZones.zoneFrom.id,
        nrOfZones: selectedZones.zoneCount,
        recipients: recipientsContract,
      });
    } else {
      orderPromise = ticketApiClient.tickets.createZoneToZoneTicket({
        agreementNumber,
        invoiceReference,
        personalReference,
        productId: selectedProduct.id,
        zoneFrom: selectedZones.zoneFrom.id,
        zoneTo: selectedZones.zoneTo === NO_ZONE ? undefined : selectedZones.zoneTo.id,
        recipients: recipientsContract,
      });
    }

    orderPromise
      .then(() => {
        setOrderInProgress(false);
        setCurrentPage(Page.Forth);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line
        setOrderInProgress(false);
        setOrderFailed(true);
      });
  };

  const selectProductTemplate = (newProductTemplate: ProductTemplate): void => {
    if (zones === null) {
      throw new Error('zones can not be null');
    }

    if (selectedProductTemplate && selectedProductTemplate.id === newProductTemplate.id) {
      setCurrentPage(Page.Second);
    } else {
      const shouldSetDefaultSelectedZone = selectedZones === null
        || selectedZones.zoneType === 'CalculationError'
        || (selectedZones.zoneType === 'Radius' && newProductTemplate.zoneType !== 'Radius')
        || (selectedZones.zoneType === 'ZoneToZone' && newProductTemplate.zoneType !== 'ZoneToZone')
        || newProductTemplate.nrOfZonesGetAll === 1;
      const defaultZoneCount = 1;

      if (shouldSetDefaultSelectedZone) {
        if (newProductTemplate.zoneType === 'Radius') {
          if (radiusZones === null) {
            throw new Error('radiusZones can not be null');
          }

          const defaultZoneFrom = radiusZones.find((x) => x.name === '1') || radiusZones[0];

          const newSelectedZone: SelectedRadiusZone = {
            zoneType: 'Radius',
            zoneCount: defaultZoneCount,
            zoneFrom: defaultZoneFrom,
            userConfirmed: newProductTemplate.nrOfZonesGetAll === 1,
          };
          setSelectedZones(newSelectedZone);
        } else {
          const defaultZoneFrom = zones.find((x) => x.name === '1') || zones[0];
          const newSelectedZone: SelectedZoneToZone = {
            zoneType: 'ZoneToZone',
            zoneFrom: defaultZoneFrom,
            zoneTo: NO_ZONE,
            zoneCount: defaultZoneCount,
            userConfirmed: newProductTemplate.nrOfZonesGetAll === 1,
          };
          setSelectedZones(newSelectedZone);
        }
      }

      let newProduct = null;

      if (selectedProduct) {
        newProduct = newProductTemplate.products.find((x) => x.profileId === selectedProduct.profileId);
      }

      if (newProduct === null && newProductTemplate.products.length === 1) {
        newProduct = newProductTemplate.products[0]; // eslint-disable-line
      }

      if (!newProduct) {
        newProduct = null;
      }
      setSelectedProductTemplate(newProductTemplate);

      setSelectedProduct(newProduct);
      setCurrentPage(Page.Second);
    }
  };

  const selectZoneZoneToZone = async (zoneFrom: Zone, zoneTo: ZoneTo): Promise<void> => {
    if (zoneTo === NO_ZONE) {
      setSelectedZones({
        zoneFrom,
        zoneTo,
        zoneCount: 1,
        zoneType: 'ZoneToZone',
        userConfirmed: selectedZones?.userConfirmed || false,
      });
    } else {
      try {
        setCalculatingDistance(true);
        const result = await ticketApiClient.zones.calculate(zoneFrom.id, zoneTo.id);
        setCalculatingDistance(false);
        setSelectedZones({
          zoneFrom,
          zoneTo,
          zoneCount: result,
          zoneType: 'ZoneToZone',
          userConfirmed: selectedZones?.userConfirmed || false,
        });
      } catch (e: unknown) {
        setCalculatingDistance(false);
        setSelectedZones({
          zoneType: 'CalculationError',
          zoneFrom,
          zoneTo,
          userConfirmed: selectedZones?.userConfirmed || false,
          zoneCount: selectedZones?.zoneCount || 1,
        });
      }
    }
  };

  const confirmZoneSelection = (): void => {
    if (!selectedZones) {
      throw new Error('selectedZones can not be null');
    }

    setSelectedZones({
      ...selectedZones,
      userConfirmed: true,
    });
  };

  const resetWidget = (): void => {
    setSelectedProductTemplate(undefined);
    setSelectedZones(null);
    setSelectedProduct(null);
    setRecipients([getDefaultRow(1)]);
    setRecipientBatches([]);
    setCalculatingDistance(false);
    setOrderInProgress(false);
    setOrderFailed(false);
    setInvoiceReference('');
    setPersonalReference('');
    setCurrentPage(Page.First);
  };

  const goBack = (): void => {
    let newPage;

    if (currentPage === Page.Second) {
      newPage = Page.First;
    } else if (currentPage === Page.Third) {
      newPage = Page.Second;
    } else if (currentPage === Page.Forth) {
      resetWidget();
    }

    if (newPage) {
      setCurrentPage(newPage);
    }
  };

  const loading = zones === null || productTemplates === null;
  const componentPropsList: ComponentProps[] = [];

  if (loading) {
    componentPropsList.push({ type: ComponentPropTypes.Loading });
  } else {
    if (currentPage === Page.First) {
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: header,
      });
      componentPropsList.push({
        type: ComponentPropTypes.ProductTemplateSelector,
        productTemplates,
        selectedProductTemplate,
        selectedLanguage: SELECTED_LANGUAGE,
        selectProductTemplate,
        priceParams: undefined,
      });
    }
    if (currentPage === Page.Second) {
      if (!selectedProductTemplate) {
        throw Error('selectedProductTemplate must have value');
      }

      componentPropsList.push({
        type: ComponentPropTypes.BackButton,
        goBack,
      });
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: selectedProductTemplate.name[SELECTED_LANGUAGE],
      });

      if (selectedZones?.zoneType === 'ZoneToZone' && selectedProductTemplate.nrOfZonesGetAll === 1) {
        componentPropsList.push({
          type: ComponentPropTypes.NoZonesRequired,
        });
      }

      if ((selectedZones?.zoneType === 'ZoneToZone' || selectedZones?.zoneType === 'CalculationError') && selectedProductTemplate.nrOfZonesGetAll !== 1) {
        componentPropsList.push({
          type: ComponentPropTypes.SelectZoneToZone,
          nrOfZonesGetAll: selectedProductTemplate.nrOfZonesGetAll,
          zones,
          selectedZones,
          calculatingDistance,
          selectZones: selectZoneZoneToZone,
          confirmZoneSelection,
        });
      }
      if (selectedZones?.zoneType === 'Radius') {
        if (!radiusZones) {
          throw Error('radiusZones must have value');
        }

        componentPropsList.push({
          type: ComponentPropTypes.SelectRadiusZone,
          selectedProductTemplate,
          zones: radiusZones,
          selectedZones,
          selectZones: (zoneFrom: RadiusZone, zoneCount: number): void => setSelectedZones({
            zoneCount,
            userConfirmed: selectedZones?.userConfirmed || false,
            zoneFrom,
            zoneType: 'Radius',
          }),
          confirmZoneSelection,
        });
      }
      if (selectedZones?.userConfirmed) {
        componentPropsList.push({
          type: ComponentPropTypes.ProductSelector,
          selectedProduct: selectedProduct || undefined,
          selectedProductTemplate,
          zoneCount: selectedZones.zoneCount,
          selectProduct: setSelectedProduct,
        });
      }
      if (selectedZones?.userConfirmed && selectedProduct) {
        componentPropsList.push({
          type: ComponentPropTypes.AddRecipients,
          recipients,
          recipientBatches,
          selectedProduct,
          selectedProductTemplate,
          zoneCount: selectedZones.zoneCount,
          disableToSummaryAndBuyButton: calculatingDistance || selectedZones.zoneType === 'CalculationError',
          setRecipientBatches,
          setRecipients,
          gotoSummary: (): void => {
            setOrderFailed(false);
            setCurrentPage(Page.Third);
          },
        });
      }
    }
    if (currentPage === Page.Third) {
      if (!selectedProductTemplate) {
        throw Error('selectedProductTemplate must have value');
      }
      if (!selectedProduct) {
        throw Error('selectedProduct must have value');
      }
      if (!selectedZones || selectedZones.zoneType === 'CalculationError') {
        throw Error('selectedZones must be valid');
      }

      componentPropsList.push({
        type: ComponentPropTypes.BackButton,
        goBack,
      });
      componentPropsList.push({
        type: ComponentPropTypes.Header,
        text: 'Oppsummering',
      });
      componentPropsList.push({
        type: ComponentPropTypes.SummaryAndOrder,
        selectedProductTemplate,
        selectedProduct,
        selectedZones,
        recipients: recipients.filter((x) => x.phoneNumber), // recipients always contains an empty entry for user input
        recipientBatches,
        selectedLanguage: SELECTED_LANGUAGE,
        personalReference,
        invoiceReference,
        orderFailed,
        orderInProgress,
        errorLink,
        setInvoiceReference,
        setPersonalReference,
        order,
      });
    }
    if (currentPage === Page.Forth) {
      componentPropsList.push({
        type: ComponentPropTypes.OrderConfirmed,
        reset: resetWidget,
      });
    }
  }
  return componentPropsList;
};

export default useRuterStateHandler;
