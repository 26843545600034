import React from 'react';
import { Product } from '../types/product';
import { ProductTemplate } from '../types/productTemplate';
import numberFormat from '../utils/numberFormatting';
import { findPrice } from '../utils/priceCalculator';
import ComponentPropTypes from './componentPropTypes';
import './ProductSelector.scss';

export interface ProductSelectorProps {
  type: ComponentPropTypes.ProductSelector
  selectedProductTemplate: ProductTemplate;
  zoneCount: number;
  selectedProduct: Product | undefined;
  selectProduct: (product: Product) => void;
}

const language = 'nb';

const sortArray = [
  { profileId: 1, sortValue: 1 }, // Voksen
  { profileId: 2, sortValue: 2 }, // Barn
  { profileId: 32, sortValue: 3 }, // Ungdom
  { profileId: 3, sortValue: 4 }, // Student
  { profileId: 4, sortValue: 5 }, // Honnør
];

const getSortValue = (product: Product): number => {
  const sortValue = sortArray.find((x) => x.profileId === product.profileId);

  if (sortValue) {
    return sortValue.sortValue;
  }

  return 999;
};

const sortProducts = (productA: Product, productB: Product): number => {
  const sortA = getSortValue(productA);
  const sortB = getSortValue(productB);
  return sortA - sortB;
};

const ProductSelector: React.FC<ProductSelectorProps> = ({
  selectedProductTemplate, zoneCount, selectedProduct, selectProduct,
}) => {
  const calculateAndFormatPrice = (product: Product): string => {
    const price = findPrice(selectedProductTemplate, product, zoneCount);

    return `${numberFormat(price)},-`;
  };

  const renderTraveler = (product: Product): JSX.Element => (
    <button
      className={`item ${selectedProduct && selectedProduct.id === product.id ? 'selected' : ''}`}
      type="button"
      key={product.id}
      onClick={(): void => selectProduct(product)}
      data-test-id={`productId=${product.id}`}
    >
      <div className="left">
        <div className="title">{product.profileName[language]}</div>
        <div className="text">
          {product.profileDescription[language] || '\u00a0'}
        </div>
      </div>
      <div className="price" data-test-id="product-selector-price">
        {calculateAndFormatPrice(product)}
      </div>
    </button>
  );

  const sortedProducts = [...selectedProductTemplate.products];
  sortedProducts.sort(sortProducts);

  return (
    <div className="section" data-test-id="product-selector">
      <h2>Velg reisende</h2>
      <div className="traveler">
        {sortedProducts.map(renderTraveler)}
      </div>
    </div>
  );
};

export default ProductSelector;
