import * as validation from '../apiClient/typeValidations';

export const NO_ZONE = 'none';

export interface ZoneJson {
  adjacentZoneIds: string[];
  adjacentZoneNames: string[];
  id: string;
  maxZones: number;
  name: string;
}

interface ZoneToZoneZone {
  id: string;
  name: string;
  type: 'ZoneToZone';
}

export interface RadiusZone {
  id: string;
  name: string;
  type: 'RadiusZone';
  maxZones: number;
}

export type Zone = ZoneToZoneZone | RadiusZone;

export type ZoneTo = Zone | typeof NO_ZONE

export const mapZone = (json: ZoneJson): Zone => {
  if (json.maxZones === null) {
    return {
      id: json.id,
      name: json.name,
      type: 'ZoneToZone',
    };
  }
  return {
    id: json.id,
    name: json.name,
    type: 'RadiusZone',
    maxZones: json.maxZones,
  };
};

export const validateZone = (json: ZoneJson): void => {
  validation.validate(json.id, 'response[].id', validation.isNonEmptyString);
  validation.validate(json.name, 'response[].name', validation.isNonEmptyString);
  validation.validate(json.maxZones, 'response[].maxZones', validation.isPositiveIntegerOrNull);
};

export const getZoneById = <ZoneType extends Zone>(zones: ZoneType[], zoneId: string): ZoneType => {
  const zone = zones.find((x) => x.id === zoneId);

  if (!zone) {
    throw new Error(`could not find zone with id=${zoneId}`);
  }

  return zone;
};
