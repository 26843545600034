export interface LocaleTextJson {
  en: string | null;
  nb: string | null;
}

export interface LocaleText {
  en: string;
  nb: string;
}

export const mapLocaleText = (json: LocaleTextJson): LocaleText => ({
  en: json.en || '',
  nb: json.nb || '',
});
