import ApiBaseClient from '../apiBaseClient';
import {
  mapZone, validateZone, Zone, ZoneJson,
} from '../../types/zone';
import * as validation from '../typeValidations';

interface CalculateJson {
  question: null;
  alternatives: AlternativesJson[];
}

interface AlternativesJson {
  adjacentZones: boolean;
  nrOfZones: number;
  prefix: null;
  via: null;
  zoneFrom: null;
  zoneTo: null;
  zoneVia: null;
}

export async function getAll(apiClient: ApiBaseClient): Promise<Zone[]> {
  const result = await apiClient.get<ZoneJson[]>('/distance/zones');
  validation.validate(result.data, 'response', validation.isNonEmptyArray);
  result.data.forEach(validateZone);

  return result.data.map(mapZone);
}

export async function calculate(apiClient: ApiBaseClient, zoneFrom: string, zoneTo: string): Promise<number> {
  validation.validate(zoneFrom, 'zoneFrom', validation.isNonEmptyString);
  validation.validate(zoneTo, 'zoneTo', validation.isNonEmptyString);

  const result = await apiClient.get<CalculateJson>(`/distance/calculate?zonetype=ZoneToZone&zonefrom=${zoneFrom}&zoneTo=${zoneTo}`);

  validation.validate(result.data.alternatives, 'response.alternatives', validation.isNonEmptyArray);

  result.data.alternatives.forEach((x) => {
    validation.validate(x.nrOfZones, 'response.alternatives[].nrOfZones', validation.isPositiveInteger);
  });

  return result.data.alternatives[0].nrOfZones;
}
