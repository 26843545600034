import { ChevronLeftIcon } from '@ruter-ds/rds-icons';
import React from 'react';
import { Icon } from '@ruter-ds/rds-components';
import ComponentPropTypes from './componentPropTypes';

export interface BackButtonProps {
  type: ComponentPropTypes.BackButton;
  goBack: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ goBack }) => (
  <button type="button" data-test-id="nav-back-button" onClick={goBack} className="back-button">
    <Icon component={<ChevronLeftIcon />} />
    <span className="text">Tilbake</span>
  </button>
);

export default BackButton;
