import {
  mapOrder, validateOrder,
} from '../../types/order';
import { OrderAndTicket, OrderAndTicketJson } from '../../types/orderAndTicket';
import { RadiusTicketRequestData, validateRadiusTicketRequestData } from '../../types/RadiusTicketRequestData';
import { SearchResult, validateSearchResult } from '../../types/searchResult';
import {
  mapTicket, validateTicket,
} from '../../types/ticket';
import { validateZoneToZoneTicketRequestData, ZoneToZoneTicketRequestData } from '../../types/ZoneToZoneTicketRequestData';
import ApiBaseClient from '../apiBaseClient';
import * as searchUtils from '../searchUtils';
import * as validation from '../typeValidations';

function normalizePhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\s/g, '');
}

function validateResponse(data: SearchResult<OrderAndTicketJson>): void {
  validateSearchResult(data);

  data.matches.forEach((match, i) => {
    validateTicket(match.ticket, `response.matches[${i}].ticket`);
    validateOrder(match.order, `response.matches[${i}].order`);
  });
}

export async function getByOrderId(apiClient: ApiBaseClient, orderId: string, query: string, pageNumber: number, pageSize: number): Promise<SearchResult<OrderAndTicket>> {
  searchUtils.validateInputParameters(orderId, query, pageNumber, pageSize);

  const elasticSearchString = searchUtils.getElasticSearchString(query);
  const offset = searchUtils.getOffset(pageNumber, pageSize);
  const result = await apiClient.get<SearchResult<OrderAndTicketJson>>(`/ticket/by-order-id/${orderId}?searchFor=${elasticSearchString}&offset=${offset}&maxEntries=${pageSize}`);

  validateResponse(result.data);

  return {
    totalEntries: result.data.totalEntries,
    matches: result.data.matches.map((x) => ({
      ticket: mapTicket(x.ticket),
      order: mapOrder(x.order),
    })),
  };
}

export async function getByCompanyId(apiClient: ApiBaseClient, companyId: string, query: string, pageNumber: number, pageSize:number): Promise<SearchResult<OrderAndTicket>> {
  searchUtils.validateInputParameters(companyId, query, pageNumber, pageSize);

  const elasticSearchString = searchUtils.getElasticSearchString(query);
  const offset = searchUtils.getOffset(pageNumber, pageSize);
  const result = await apiClient.get<SearchResult<OrderAndTicketJson>>(`/ticket/by-company-id/${companyId}?searchFor=${elasticSearchString}&offset=${offset}&maxEntries=${pageSize}`);

  validateResponse(result.data);

  return {
    totalEntries: result.data.totalEntries,
    matches: result.data.matches.map((x) => ({
      ticket: mapTicket(x.ticket),
      order: mapOrder(x.order),
    })),
  };
}

export async function createRadiusTicket(apiClient: ApiBaseClient, data: RadiusTicketRequestData): Promise<void> {
  validateRadiusTicketRequestData(data);

  const {
    agreementNumber,
    invoiceReference,
    personalReference,
    productId,
    zoneFrom,
    nrOfZones,
    recipients,
  } = data;

  await apiClient.post('/ticket/create', {
    agreementNumber,
    invoiceReference,
    personalReference,
    productId,
    zoneFrom,
    nrOfZones,
    recipients: recipients.map((x) => ({ ...x, phone: normalizePhoneNumber(x.phone) })),
  });
}

export async function createZoneToZoneTicket(apiClient: ApiBaseClient, data: ZoneToZoneTicketRequestData) : Promise<void> {
  validateZoneToZoneTicketRequestData(data);

  const {
    agreementNumber,
    invoiceReference,
    personalReference,
    productId,
    zoneFrom,
    zoneTo,
    recipients,
  } = data;

  await apiClient.post('/ticket/create', {
    agreementNumber,
    invoiceReference,
    personalReference,
    productId,
    zoneFrom,
    zoneTo,
    recipients: recipients.map((x) => ({ ...x, phone: normalizePhoneNumber(x.phone) })),
  });
}

export async function resendPickupCode(apiClient: ApiBaseClient, ticketId: string): Promise<void> {
  validation.validate(ticketId, 'ticketId', validation.isNonEmptyString);
  await apiClient.post(`/ticket/resend-pickup-code/${ticketId}`, {});
}

export async function cancelPending(apiClient: ApiBaseClient, ticketId: string): Promise<void> {
  validation.validate(ticketId, 'ticketId', validation.isNonEmptyString);
  await apiClient.post(`/ticket/cancel-pending/${ticketId}`, {});
}
