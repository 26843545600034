/* eslint-disable react/no-array-index-key */
import {
  Button, FieldMessage, Input, Stepper,
} from '@ruter-ds/rds-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import React, { FormEvent, useEffect, useState } from 'react';
import { Product } from '../types/product';
import { ProductTemplate } from '../types/productTemplate';
import { Recipient } from '../types/Recipient';
import { RecipientBatch } from '../types/RecipientBatch';
import numberFormat from '../utils/numberFormatting';
import { calculateTotalPrice, getTicketCount } from '../utils/priceCalculator';
import ComponentPropTypes from './componentPropTypes';
import RecipientBatches from './RecipientBatches';
import './Recipients.scss';

const getDuplicateRecipients = (recipients: Recipient[], recipientBatches: RecipientBatch[]): Recipient[] => {
  const duplicates: Recipient[] = [];

  const uniqueList: string[] = [];
  recipientBatches.forEach((batch) => {
    const phoneNumbers = batch.validPhoneNumbers.map((x) => parsePhoneNumberFromString(`${x.phoneCountryCode}${x.phone}`)?.format('INTERNATIONAL')).filter((x): x is string => Boolean(x));
    uniqueList.push(...phoneNumbers);
  });

  recipients.forEach((x) => {
    if (uniqueList.indexOf(x.phoneNumber) === -1) {
      uniqueList.push(x.phoneNumber);
    } else {
      duplicates.push(x);
    }
  });

  return duplicates;
};

const markRowsAsDuplicate = (recipients: Recipient[], recipientBatches: RecipientBatch[]): Recipient[] => {
  const duplicateRecipients = getDuplicateRecipients(recipients, recipientBatches);
  const updatedRecipients = recipients.map((x) => ({
    ...x,
    duplicate: duplicateRecipients.indexOf(x) !== -1,
  }));

  return updatedRecipients;
};

const normalizePhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/\s/g, '');

const isPhoneNumberValid = (phoneNumber: string): boolean => {
  const testValue = normalizePhoneNumber(phoneNumber);

  if (!testValue) {
    return true;
  }

  const international = testValue.indexOf('+') === -1 ? `+47${testValue}` : testValue;

  const parsed = parsePhoneNumberFromString(international);
  if (parsed && parsed.isValid()) {
    const type = parsed.getType();
    const validTypes = [
      undefined,
      'MOBILE',
      'FIXED_LINE_OR_MOBILE',
      'PERSONAL_NUMBER',
    ];
    if (validTypes.indexOf(type) !== -1) {
      return true;
    }
  }

  return false;
};

const formatPhoneNumber = (phoneNumber: string): string => {
  const international = phoneNumber.indexOf('+') === -1 ? `+47${phoneNumber}` : phoneNumber;
  const parsed = parsePhoneNumberFromString(international);

  if (parsed && parsed.isValid()) {
    return parsed.format('INTERNATIONAL');
  }
  return phoneNumber;
};

export const getDefaultRow = (id: number): Recipient => ({
  id,
  phoneNumber: '',
  error: false,
  duplicate: false,
  showError: false,
  count: 0,
  duplicates: [],
});

export interface AddRecipientsProps {
  type: ComponentPropTypes.AddRecipients
  selectedProductTemplate: ProductTemplate;
  selectedProduct: Product;
  zoneCount: number;
  recipients: Recipient[];
  recipientBatches: RecipientBatch[];
  disableToSummaryAndBuyButton: boolean;
  setRecipients: (recipients: Recipient[]) => void;
  setRecipientBatches: (recipientBatches: RecipientBatch[]) => void;
  gotoSummary: () => void;
}

const Recipients: React.FC<AddRecipientsProps> = ({
  selectedProductTemplate, selectedProduct, zoneCount, recipients, recipientBatches, disableToSummaryAndBuyButton, setRecipients, setRecipientBatches, gotoSummary,
}) => {
  const [showErrorSummary, setShowErrorSummary] = useState(false);

  const updateRecipients = (updatedRecipients: Recipient[]): void => {
    let output = recipients.filter((x) => !updatedRecipients.some((y) => y.id === x.id));
    output = [...output, ...updatedRecipients];
    output = output.sort((a, b) => a.id - b.id);
    setRecipients(output);
  };

  useEffect(() => {
    let updatedRecipients = markRowsAsDuplicate(recipients, recipientBatches);

    // adds empty recipient input so the user can type in a phone number
    if (!recipients.some((x) => x.phoneNumber === '')) {
      updatedRecipients = [...updatedRecipients, getDefaultRow(1)];
    }
    updateRecipients(updatedRecipients);
  }, [recipientBatches]);

  const getRecipient = (id: number): Recipient => {
    const recipient = recipients.find((x) => x.id === id);

    if (!recipient) {
      throw new Error(`could not find recipient with id=${id}`);
    }

    return recipient;
  };

  const onToSummaryClick = (): void => {
    const hasPhoneNumbers = recipients.some((x) => x.phoneNumber) || recipientBatches.some((x) => x.validPhoneNumbers.length > 0);
    const hasInvalidPhoneNumbers = recipients.some((x) => x.error);

    if (!hasPhoneNumbers) {
      setShowErrorSummary(true);
      return;
    }

    if (hasInvalidPhoneNumbers) {
      setShowErrorSummary(true);
      return;
    }

    const duplicateRecipients = getDuplicateRecipients(recipients, recipientBatches);
    if (duplicateRecipients.length) {
      setShowErrorSummary(true);

      const updatedRecipients = markRowsAsDuplicate(recipients, recipientBatches);
      updateRecipients(updatedRecipients);
      return;
    }

    gotoSummary();
  };

  const createRecipient = (): Recipient => {
    const highestId = Math.max(...recipients.map((x) => x.id));
    return getDefaultRow(highestId + 1);
  };

  const onPhoneNumberChange = (id: number, phoneNumber: string): void => {
    const recipient = getRecipient(id);

    const oldPhoneNumber = recipient.phoneNumber;
    let { count } = recipient;
    const error = !isPhoneNumberValid(phoneNumber);

    if (phoneNumber && count === 0) {
      count = 1;
    } else if (!phoneNumber) {
      count = 0;
    }

    const isChanged = normalizePhoneNumber(oldPhoneNumber) !== normalizePhoneNumber(phoneNumber);
    const hasDuplicates = recipients.some((x) => x.duplicate);

    const updatedRow = {
      ...recipient,
      phoneNumber,
      count,
      error,
    };

    let affectedRows = [updatedRow];

    if (isChanged && hasDuplicates && !error) {
      affectedRows = markRowsAsDuplicate([
        ...recipients.filter((x) => x.id !== recipient.id),
        updatedRow,
      ], recipientBatches);
    }

    const isLastNumber = recipients.indexOf(recipient) === recipients.length - 1;
    const phoneNumberWasEmpty = !recipient.phoneNumber;
    const shouldPushAnotherRow = isLastNumber && phoneNumberWasEmpty;

    if (shouldPushAnotherRow) {
      affectedRows.push(createRecipient());
    }

    updateRecipients(affectedRows);
    setShowErrorSummary(false);
  };

  const onPhoneNumberBlur = (id: number): void => {
    const recipient = getRecipient(id);
    const { phoneNumber } = recipient;

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    const updatedRow = {
      ...recipient,
      phoneNumber: formattedPhoneNumber,
      showError: true,
    };
    updateRecipients([updatedRow]);
  };

  const onCountIncrease = (id: number): void => {
    const recipient = getRecipient(id);
    const { count } = recipient;
    const updatedRecipient = { ...recipient, count: count + 1 };
    updateRecipients([updatedRecipient]);
  };

  const onCountDecrease = (id: number): void => {
    const recipient = getRecipient(id);
    const { count } = recipient;
    const updatedRow = { ...recipient, count: count - 1 };
    updateRecipients([updatedRow]);
  };

  const removeRecipient = (id: number): void => {
    setRecipients(recipients.filter((x) => x.id !== id));
  };

  const renderRecipient = (recipient: Recipient): JSX.Element => {
    const showRemoveButton = recipients.indexOf(recipient) !== recipients.length - 1;

    const min = recipient.phoneNumber ? 1 : 0;
    const max = recipient.phoneNumber ? 25 : 0;
    const count = recipient.phoneNumber ? recipient.count : 0;

    return (

      <div className="row" data-test-id="input-row" data-row-id={recipient.id} key={recipient.id}>
        <div className="inputs">
          <Input
            className="input"
            data-test-id="phone-number"
            name={`phone.${recipient.id}`}
            value={recipient.phoneNumber}
            hasError={(recipient.error || recipient.duplicate) && recipient.showError}
            onChange={(event: FormEvent<HTMLInputElement>): void => { onPhoneNumberChange(recipient.id, event.currentTarget.value); }}
            onBlur={(): void => { onPhoneNumberBlur(recipient.id); }}
            autoComplete="new-phone"
          />
          <Stepper
            label=""
            className="stepper"
            min={min}
            max={max}
            value={count.toString()}
            roundButtons
            size="small"
            increaseValue={(): void => onCountIncrease(recipient.id)}
            decreaseValue={(): void => onCountDecrease(recipient.id)}
          />
          <div className="remove-button-container">
            {showRemoveButton && <button className="remove" type="button" data-test-id="remove-line-button" onClick={(): void => removeRecipient(recipient.id)}>Fjern</button>}
          </div>
        </div>
        {recipient.showError && recipient.error && (
          <FieldMessage data-test-id="phone-validation-error" skin="danger" text="Telefonnummer må være et gyldig mobilnummer" />
        )}
        {recipient.showError && recipient.duplicate && (
          <FieldMessage data-test-id="phone-duplicate-error" skin="danger" text="Telefonnummeret finnes allerede i listen" />
        )}
      </div>

    );
  };

  const totalCount = getTicketCount(recipients, recipientBatches);
  const totalPrice = numberFormat(calculateTotalPrice(selectedProductTemplate, selectedProduct, zoneCount, recipients, recipientBatches));

  const noPhoneNumbers = !recipients.some((x) => x.phoneNumber) && !recipientBatches.some((x) => x.validPhoneNumbers.length > 0);
  const hasValidationError = recipients.some((x) => x.error);
  const hasDuplicates = recipients.some((x) => x.duplicate);

  return (
    <div className="section" data-test-id="recipients-selector">
      <h2>Mottakere</h2>
      <div className="recipients">
        <RecipientBatches recipientBatches={recipientBatches} updateRecipientBatches={setRecipientBatches} />
        <div className="rows">
          <div className="row">
            <div className="inputs">
              <div className="phone">Telefonnummer</div>
              <div className="count">Antall billetter</div>
            </div>
          </div>
          {recipients.map(renderRecipient)}
        </div>
        <div className="summary">
          <div className="row">
            <div className="label">Total antall billetter</div>
            <div className="value" data-test-id="total-count">{totalCount}</div>
          </div>
          <div className="row">
            <div className="label">Total pris</div>
            <div className="value" data-test-id="total-price">{`kr ${totalPrice}`}</div>
          </div>
        </div>
      </div>
      {showErrorSummary && noPhoneNumbers && (
      <FieldMessage className="no-phone-numbers-error" data-test-id="no-phone-numbers-error" skin="danger" text="Feil: Du må legge til minst ett telefonnummer" />
      )}
      {showErrorSummary && hasValidationError && (
      <FieldMessage className="hasInvalidPhoneNumbers" data-test-id="invalid-phone-numbers-error" skin="danger" text="Feil: en eller flere av telefonnumrene har feil format" />
      )}
      {showErrorSummary && hasDuplicates && (
      <FieldMessage className="hasInvalidPhoneNumbers" data-test-id="duplicate-phone-numbers-error" skin="danger" text="Feil: en eller flere av telefonnumrene forekommer flere ganger i listen" />
      )}
      <Button
        text="Til oppsummering"
        variant="filled"
        skin="success"
        className="to-order-page-button"
        onClick={onToSummaryClick}
        data-test-id="to-order-summary-button"
        disabled={disableToSummaryAndBuyButton}
      />
    </div>
  );
};

export default Recipients;
