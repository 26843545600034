import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthenticationService } from '../types/authenticationService';

const cleanUpBaseUrl = (baseUrl: string): string => {
  let modifiedBaseUrl = baseUrl;

  if (modifiedBaseUrl[0] !== '/') {
    modifiedBaseUrl = `/${modifiedBaseUrl}`;
  }

  if (modifiedBaseUrl[modifiedBaseUrl.length - 1] === '/') {
    modifiedBaseUrl = modifiedBaseUrl.substr(0, modifiedBaseUrl.length - 1);
  }
  return modifiedBaseUrl;
};

function removeMultipleForwardSlashes(url: string): string {
  return url.replace(/\/+/g, '/');
}

function validateRelativeUrl(url: string): void {
  if (!url || typeof url !== 'string' || url[0] !== '/') {
    throw new Error('url must be a non-empty string starting with /');
  }
}

function validateRequestBody(requestBody: object): void {
  if (requestBody !== undefined) {
    if (!requestBody || typeof requestBody !== 'object') {
      throw new Error('request body must be an object or undefined');
    }
  }
}

async function handleResponse<ResponseType>(response: Promise<AxiosResponse<ResponseType, unknown>>): Promise<AxiosResponse<ResponseType, unknown>> {
  const result = await response;
  if (result && typeof result.data !== 'object') {
    throw new Error('response was not valid json');
  }
  return result;
}

export default class ApiBaseClient {
  private __getBaseUrl__;

  private __getAuthenticationService__;

  constructor(baseUrl: string, authenticationService: AuthenticationService) {
    if (!baseUrl || typeof baseUrl !== 'string') {
      throw new Error('baseUrl must be a non-empty string');
    }

    if (authenticationService !== undefined) {
      if (!authenticationService || typeof authenticationService !== 'object' || typeof authenticationService.getAccessToken !== 'function') {
        throw new Error('authenticationService must be an object or undefined');
      }
    }

    this.__getBaseUrl__ = () => cleanUpBaseUrl(baseUrl); // eslint-disable-line
    this.__getAuthenticationService__ = () => authenticationService; // eslint-disable-line
  }

  get baseUrl() { return this.__getBaseUrl__(); } // eslint-disable-line
  get authenticationService() { return this.__getAuthenticationService__(); } // eslint-disable-line

  async getRequestOptions(): Promise<AxiosRequestConfig<unknown> | undefined> {
    let options;
    let token;

    try {
      token = this.authenticationService ? await this.authenticationService.getAccessToken() : '';
    } catch (e) {
      throw new Error('getAccessToken failed');
    }

    if (token) {
      options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return options;
  }

  async get<ResponseType>(url: string): Promise<AxiosResponse<ResponseType, unknown>> {
    validateRelativeUrl(url);

    // remove double forward slashes
    const fullUrl = removeMultipleForwardSlashes(this.baseUrl + url);
    const options = await this.getRequestOptions();

    return handleResponse<ResponseType>(axios.get(fullUrl, options));
  }

  async post(url: string, requestBody: object): Promise<AxiosResponse<ResponseType, unknown>> {
    validateRelativeUrl(url);
    validateRequestBody(requestBody);

    const fullUrl = removeMultipleForwardSlashes(this.baseUrl + url);
    const options = await this.getRequestOptions();

    return handleResponse(axios.post(fullUrl, requestBody, options));
  }
}
