import * as validation from '../apiClient/typeValidations';
import { parseTicketStatus, TicketStatus } from './ticketStatus';

export interface TicketJson {
  id: string;
  status: string;
  downloadedToPhone: boolean;
  phone: string;
  phoneCountryCode: string;
  pickupCode: string | null;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  productTemplateId: number;
  productPrice: number;
}

export interface Ticket {
  id: string;
  status: TicketStatus;
  downloadedToPhone: boolean;
  phone: string;
  phoneCountryCode: string;
  pickupCode: string | null;
  zoneFrom: string;
  zoneTo: string;
  zoneVia: string;
  productTemplateId: number;
  productPrice: number;
}

export const mapTicket = (json: TicketJson): Ticket => ({
  id: json.id,
  status: parseTicketStatus(json.status),
  downloadedToPhone: json.downloadedToPhone,
  phone: json.phone,
  phoneCountryCode: json.phoneCountryCode,
  pickupCode: json.pickupCode,
  zoneFrom: json.zoneFrom,
  zoneTo: json.zoneTo,
  zoneVia: json.zoneVia,
  productTemplateId: json.productTemplateId,
  productPrice: json.productPrice,
});

export const validateTicket = (json: TicketJson, path: string): void => {
  validation.validate(json, `${path}`, validation.isObject);
  validation.validate(json.id, `${path}.id`, validation.isNonEmptyString);
  validation.validate(json.status, `${path}.status`, validation.isStringOfValue('active', 'pending', 'expired', 'cancelled', 'failed', 'unknown'));
  validation.validate(json.downloadedToPhone, `${path}.downloadedToPhone`, validation.isBoolean);
  validation.validate(json.phone, `${path}.phone`, validation.isNonEmptyString);
  validation.validate(json.phoneCountryCode, `${path}.phoneCountryCode`, validation.isStringPhoneCountryCode);
  validation.validate(json.pickupCode, `${path}.pickupCode`, validation.isStringOrNull);
  validation.validate(json.zoneFrom, `${path}.zoneFrom`, validation.isNonEmptyString);
  validation.validate(json.zoneTo, `${path}.zoneTo`, validation.isString);
  validation.validate(json.zoneVia, `${path}.zoneVia`, validation.isString);
  validation.validate(json.productTemplateId, `${path}.productTemplateId`, validation.isPositiveInteger);
  validation.validate(json.productPrice, `${path}.productPrice`, validation.isNonNegativeMoney);
};
