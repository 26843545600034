import * as validation from '../apiClient/typeValidations';

export interface SearchResult<DataType> {
  totalEntries: number;
  matches: DataType[];
}

export const validateSearchResult = (json: SearchResult<unknown>): void => {
  validation.validate(json.totalEntries, 'response.totalEntries', validation.isNonNegativeInteger);
  validation.validate(json.matches, 'response.matches', validation.isArray);
};
