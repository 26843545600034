import React from 'react';
import { Message } from '@ruter-ds/rds-components';
import ComponentPropTypes from './componentPropTypes';

export interface NoZonesRequriedProps {
  type: ComponentPropTypes.NoZonesRequired;
}

const NoZonesRequried: React.FC<NoZonesRequriedProps> = () => (
  <Message skin="info" data-test-id="all-zones-message" style={{ marginBottom: '1rem' }}>
    Ikke nødvendig å angi reisestrekning.
  </Message>
);

export default NoZonesRequried;
