import { RecipientRequestData, validateRecipientRequestData } from './RecipientRequestData';
import * as validation from '../apiClient/typeValidations';

export interface RadiusTicketRequestData {
  agreementNumber: string;
  invoiceReference: string;
  personalReference: string;
  productId: number;
  zoneFrom: string;
  nrOfZones: number;
  recipients: RecipientRequestData[];
}

export const validateRadiusTicketRequestData = (data: RadiusTicketRequestData): void => {
  validation.validate(data.agreementNumber, 'agreementNumber', validation.isNonEmptyString);
  validation.validate(data.invoiceReference, 'invoiceReference', validation.isString);
  validation.validate(data.personalReference, 'personalReference', validation.isString);
  validation.validate(data.productId, 'productId', validation.isPositiveInteger);
  validation.validate(data.zoneFrom, 'zoneFrom', validation.isNonEmptyString);
  validation.validate(data.nrOfZones, 'nrOfZones', validation.isPositiveInteger);
  validation.validate(data.recipients, 'recipients', validation.isNonEmptyArray);

  data.recipients.forEach((x, i) => validateRecipientRequestData(x, `recipients[${i}].`));
};
