import moment from 'moment';

const parseDate = (value: string | null | undefined): Date => {
  const date = moment(value, moment.ISO_8601, true);

  if (date.isValid()) {
    return date.toDate();
  }
  throw new Error(`${value} is not a valid date`);
};

export default parseDate;
