/* eslint-disable react/no-array-index-key */
import { Message, Modal, Stepper } from '@ruter-ds/rds-components';
import { ExclamationCircleIcon, TickIcon } from '@ruter-ds/rds-icons';
import { PhoneNumber } from 'libphonenumber-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import React, { FormEvent, useState } from 'react';
import './RecipientBatches.scss';
import {
  PhoneValidationError, PhoneValidationErrorType, PhoneValidationResult, PhoneValidationSuccess,
} from '../types/PhoneValidationResult';
import { RecipientBatch } from '../types/RecipientBatch';

interface Props {
  recipientBatches: RecipientBatch[];
  updateRecipientBatches: (recipientBatches: RecipientBatch[]) => void;
}

let uniquePhoneNumbers: string[] = [];

const RecipientBatches: React.FC<Props> = ({ updateRecipientBatches, recipientBatches }) => {
  const [viewRecipientBatch, setViewRecipientBatch] = useState<RecipientBatch | undefined>(undefined);

  const onCountIncrease = (id: number): void => {
    const recipientBatch = recipientBatches.find((x) => x.id === id);

    if (!recipientBatch) {
      throw new Error(`Could not find recipientBatch with id=${id}`);
    }

    const indexOfRecipientBatchToUpdate = recipientBatches.indexOf(recipientBatch);

    const updatedRecipientBatch = {
      ...recipientBatch,
      ticketCount: recipientBatch.ticketCount + 1,
    };

    const updatedRecipientBatches = [
      ...recipientBatches.slice(0, indexOfRecipientBatchToUpdate),
      updatedRecipientBatch,
      ...recipientBatches.slice(indexOfRecipientBatchToUpdate + 1),
    ];

    updateRecipientBatches(updatedRecipientBatches);
  };

  const onCountDecrease = (id: number): void => {
    const recipientBatch = recipientBatches.find((x) => x.id === id);

    if (!recipientBatch) {
      throw new Error(`Could not find recipientBatch with id=${id}`);
    }

    const indexOfRecipientBatchToUpdate = recipientBatches.indexOf(recipientBatch);

    const updatedRecipientBatch = {
      ...recipientBatch,
      ticketCount: recipientBatch.ticketCount - 1,
    };

    const updatedRecipientBatches = [
      ...recipientBatches.slice(0, indexOfRecipientBatchToUpdate),
      updatedRecipientBatch,
      ...recipientBatches.slice(indexOfRecipientBatchToUpdate + 1),
    ];

    updateRecipientBatches(updatedRecipientBatches);
  };

  const isDuplicate = (phoneNumber: PhoneNumber): boolean => {
    const formattedPhoneNumber = phoneNumber.format('INTERNATIONAL');
    const index = uniquePhoneNumbers.indexOf(formattedPhoneNumber);

    if (index === -1) {
      uniquePhoneNumbers.push(formattedPhoneNumber);
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (value: string): PhoneValidationResult => {
    const phoneNumber = value.indexOf('+') !== -1 ? value : `+47${value}`;
    const parsed = parsePhoneNumberFromString(phoneNumber);
    if (parsed && parsed.isValid()) {
      const type = parsed.getType();
      const validTypes = [
        undefined,
        'MOBILE',
        'FIXED_LINE_OR_MOBILE',
        'PERSONAL_NUMBER',
      ];
      if (validTypes.indexOf(type) === -1) {
        return {
          success: false,
          value: parsed.format('INTERNATIONAL'),
          error: PhoneValidationErrorType.PHONE_NUMBER_ERROR_NOT_MOBILE,
        };
      }
      if (isDuplicate(parsed)) {
        return {
          success: false,
          value: parsed.format('INTERNATIONAL'),
          error: PhoneValidationErrorType.PHONE_NUMBER_ERROR_DUPLICATE,
        };
      }
      return {
        success: true,
        phoneCountryCode: `+${parsed.countryCallingCode}`,
        phone: parsed.nationalNumber,
      };
    }
    return {
      success: false,
      value,
      error: PhoneValidationErrorType.PHONE_NUMBER_ERROR_INVALID_FORMAT,
    };
  };

  const resetDuplicationList = (): void => {
    uniquePhoneNumbers = [];
  };

  const createRecipientBatch = (id: number, name: string, rows: string[]): RecipientBatch => {
    const valid: PhoneValidationSuccess[] = [];
    const invalid: PhoneValidationError[] = [];

    rows.forEach((x) => {
      const validationResult = validatePhoneNumber(x);
      if (!validationResult.success) {
        invalid.push(validationResult);
      } else {
        valid.push(validationResult);
      }
    });

    return {
      id,
      name,
      validPhoneNumbers: valid,
      invalidPhoneNumbers: invalid,
      ticketCount: 1,
      rows,
    };
  };

  const removeRecipientBatch = (id: number): void => {
    resetDuplicationList();

    // the call to createRecipientBatch ensures the updating of duplicates
    const updatedRecipientBatches = recipientBatches
      .filter((x) => x.id !== id)
      .map((x) => createRecipientBatch(x.id, x.name, x.rows));

    updateRecipientBatches(updatedRecipientBatches);
  };

  const addRecipientBatch = (event: FormEvent<HTMLInputElement>): void => {
    const reader = new FileReader();

    if (!event.currentTarget.files) {
      throw new Error('no files');
    }

    const { name } = event.currentTarget.files[0];

    let nextId = 1;
    if (recipientBatches.length > 0) {
      nextId = Math.max(...recipientBatches.map((x) => x.id)) + 1;
    }

    reader.onload = (e: ProgressEvent<FileReader>): void => {
      if (typeof e.target?.result !== 'string') {
        throw new Error(`${e.target?.result} is not a string`);
      }

      const { result } = e.target;
      const rows = result.match(/[^\r\n]+/g) || [];

      const recipientBatch = createRecipientBatch(nextId, name, rows);

      updateRecipientBatches([...recipientBatches, recipientBatch]);
    };

    reader.readAsText(event.currentTarget.files[0]);
  };

  const renderRecipientBatchDetails = (): JSX.Element | null => {
    if (!viewRecipientBatch) {
      return null;
    }

    const showInvalid = viewRecipientBatch.invalidPhoneNumbers.length > 0;
    const showValid = viewRecipientBatch.validPhoneNumbers.length > 0;

    const getErrorMessage = (errorCode: PhoneValidationErrorType): string => {
      if (errorCode === PhoneValidationErrorType.PHONE_NUMBER_ERROR_INVALID_FORMAT) {
        return 'Ugyldig telefonnummer';
      }
      if (errorCode === PhoneValidationErrorType.PHONE_NUMBER_ERROR_NOT_MOBILE) {
        return 'Ugyldig mobilnummer';
      }
      if (errorCode === PhoneValidationErrorType.PHONE_NUMBER_ERROR_DUPLICATE) {
        return 'Flere oppføringer';
      }
      return '';
    };

    return (
      <div data-test-id="recipient-batch-details" className="recipient-batch-details">
        {showInvalid && (
          <div data-test-id="invalid-section">
            <Message
              data-test-id="fail-message"
              skin="danger"
              icon={<ExclamationCircleIcon />}
            >
              {`Kunne ikke importere ${viewRecipientBatch.invalidPhoneNumbers.length} telefonnumre`}
            </Message>
            <table>
              <thead>
                <tr>
                  <th>Verdi</th>
                  <th>Feilmelding</th>
                </tr>
              </thead>
              <tbody>
                {viewRecipientBatch.invalidPhoneNumbers.map((x, i) => (
                  <tr key={i}>
                    <td className="value">{x.value}</td>
                    <td>{getErrorMessage(x.error)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {showValid && (
          <div data-test-id="valid-section">
            <Message
              data-test-id="success-message"
              skin="success"
              icon={<TickIcon />}
            >
              {`${viewRecipientBatch.validPhoneNumbers.length} telefonnumre importert`}
            </Message>
            <table className="valid-table">
              <thead>
                <tr>
                  <th>Telefonnummer</th>
                </tr>
              </thead>
              <tbody>
                {viewRecipientBatch.validPhoneNumbers.map((x, i) => {
                  const phoneNumber = parsePhoneNumberFromString(x.phoneCountryCode + x.phone);
                  return (
                    <tr key={i}>
                      <td>{phoneNumber?.format('INTERNATIONAL')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const renderRecipientBatch = (recipientBatch: RecipientBatch): JSX.Element => {
    const validCount = recipientBatch.validPhoneNumbers.length;
    const invalidCount = recipientBatch.invalidPhoneNumbers.length;
    const totalCount = validCount + invalidCount;

    return (
      <div className="recipient-batch" data-test-id="recipient-batch" data-recipient-batch-id={recipientBatch.id} key={recipientBatch.id}>
        <div className="header-row">
          <div data-test-id="file-name" className="file-name">{recipientBatch.name}</div>
          <button data-test-id="remove-batch-button" className="remove-batch-button" type="button" onClick={(): void => removeRecipientBatch(recipientBatch.id)}>Fjern</button>
        </div>
        <div data-test-id="valid-phone-numbers" className="valid-phone-number">{`${validCount} gyldige telefonnumre`}</div>
        <div data-test-id="invalid-phone-numbers" className="invalid-phone-number">{`${invalidCount} ugyldige telefonnumre`}</div>

        {
          totalCount > 0 && (
            <button data-test-id="show-more-button" className="show-more-button" type="button" onClick={(): void => setViewRecipientBatch(recipientBatch)}>Se mer</button>
          )
        }

        {
          validCount > 0 && (
            <div data-test-id="stepper-section">
              <div className="stepper-header">Antall billetter per telefonnummer</div>
              <Stepper
                label=""
                className="batch-stepper"
                min={1}
                max={25}
                value={recipientBatch.ticketCount.toString()}
                roundButtons
                size="small"
                increaseValue={(): void => onCountIncrease(recipientBatch.id)}
                decreaseValue={(): void => onCountDecrease(recipientBatch.id)}
              />
            </div>
          )
        }

      </div>
    );
  };

  return (
    <div className="recipient-batches">
      <div className="actions">
        <label htmlFor="file" className="file-input-label">
          Import fra CSV-fil
          <input id="file" type="file" onChange={addRecipientBatch} value="" />
        </label>
      </div>
      {recipientBatches.map(renderRecipientBatch)}

      <Modal isOpen={Boolean(viewRecipientBatch)} title={viewRecipientBatch && viewRecipientBatch.name} handleClose={(): void => setViewRecipientBatch(undefined)}>
        {renderRecipientBatchDetails()}
      </Modal>
    </div>
  );
};

export default RecipientBatches;
