import { BlockLink, BlockLinkList } from '@ruter-ds/rds-components';
import React from 'react';
import FerryIcon from '../icons/ferry';
import PeriodTicketBusIcon from '../icons/period-ticket-bus';
import SingleTicketBusIcon from '../icons/single-ticket-bus';
import SpeedBoatIcon from '../icons/speed-boat';
import YouthTicketIcon from '../icons/youth-ticket';
import { ProductCategory, ProductCategoryId } from '../types/productCategory';
import ComponentPropTypes from './componentPropTypes';
import './ProductCategory.scss';

export interface ProductCategoryProps {
  type: ComponentPropTypes.ProductCategory,
  productCategories: ProductCategory[];
  selectedProductCategory: ProductCategory | undefined;
  selectProductCategory: (productCategory: ProductCategory) => void;
}

const getIcon = (id: ProductCategoryId): JSX.Element => {
  switch (id) {
    case 'single-ticket-bus': return <SingleTicketBusIcon />;
    case 'period-ticket-bus': return <PeriodTicketBusIcon />;
    case 'youth-total': return <YouthTicketIcon />;
    case 'ferry': return <FerryIcon />;
    case 'speed-boat': return <SpeedBoatIcon />;
    default: throw new Error(`Could not find icon for ${id}`);
  }
};

const ProductCategorySelector: React.FC<ProductCategoryProps> = ({ productCategories, selectedProductCategory, selectProductCategory }) => {
  const renderType = (productCategory: ProductCategory): JSX.Element => {
    const selected = selectedProductCategory && productCategory.id === selectedProductCategory.id;

    return (
      <BlockLink
        className={`product-category ${selected ? 'selected' : ''}`}
        data-test-id={`productCategoryId=${productCategory.id}`}
        onClick={(): void => selectProductCategory(productCategory)}
        icon={getIcon(productCategory.id)}
        key={productCategory.id}
      >

        {productCategory.text}
      </BlockLink>
    );
  };

  return (
    <div className="section">
      <h2>Velg billett</h2>
      <BlockLinkList data-test-id="product-category-selector">
        {productCategories.map(renderType)}
      </BlockLinkList>
    </div>
  );
};

export default ProductCategorySelector;
