import ApiBaseClient from '../apiBaseClient';
import {
  mapProductTemplate, ProductTemplate, ProductTemplateJson, validateProductTemplate,
} from '../../types/productTemplate';
import * as validation from '../typeValidations';

export async function getAll(apiClient: ApiBaseClient, assertPriceForEveryZone: boolean): Promise<ProductTemplate[]> { // eslint-disable-line
  const result = await apiClient.get<ProductTemplateJson[]>('/product/list');

  validation.validate(result.data, 'response[]', validation.isNonEmptyArray);

  result.data.forEach((p, i) => validateProductTemplate(p, i, assertPriceForEveryZone));

  return result.data.map(mapProductTemplate);
}
