import { PriceForNumberOfZones } from '../types/priceForNumberOfZones';
import { Product } from '../types/product';
import { ProductTemplate } from '../types/productTemplate';
import { Recipient } from '../types/Recipient';
import { RecipientBatch } from '../types/RecipientBatch';

const findPriceObject = (selectedProductTemplate:ProductTemplate, selectedProduct: Product, zoneCount: number): PriceForNumberOfZones => {
  const { nrOfZonesGetAll } = selectedProductTemplate;
  const maxZoneCountInPriceArray = Math.max(...selectedProduct.prices.map((x) => x.nrOfZones));
  const zoneCountToCalculatePrice = Math.min(maxZoneCountInPriceArray, zoneCount, nrOfZonesGetAll);

  const price = selectedProduct.prices.find((x) => x.nrOfZones === zoneCountToCalculatePrice);

  if (!price) {
    throw new Error(`couldnt find price for zonecount:${zoneCount} for product.id=${selectedProduct.id}`);
  }

  return price;
};

export const findPrice = (selectedProductTemplate: ProductTemplate, selectedProduct: Product, zoneCount: number): number => {
  const price = findPriceObject(selectedProductTemplate, selectedProduct, zoneCount);

  return price.priceInclVAT;
};

const findVat = (selectedProductTemplate: ProductTemplate, selectedProduct: Product, zoneCount: number): number => {
  const price = findPriceObject(selectedProductTemplate, selectedProduct, zoneCount);
  return price.vat;
};

export const getTicketCount = (recipients: Recipient[], recipientBatches: RecipientBatch[]): number => {
  let totalCount = 0;
  recipients.forEach((x) => { totalCount += x.count; });

  recipientBatches.forEach((x) => {
    totalCount += x.validPhoneNumbers.length * x.ticketCount;
  });

  return totalCount;
};

export const calculateTotalPrice = (
  selectedProductTemplate: ProductTemplate,
  selectedProduct: Product,
  zoneCount: number,
  recipients: Recipient[],
  recipientBatches: RecipientBatch[],
): number => {
  const price = findPrice(selectedProductTemplate, selectedProduct, zoneCount);
  const count = getTicketCount(recipients, recipientBatches);
  return price * count;
};

export const calculateTotalVat = (
  selectedProductTemplate: ProductTemplate,
  selectedProduct: Product,
  zoneCount: number,
  recipients: Recipient[],
  recipientBatches: RecipientBatch[],
): number => {
  const vat = findVat(selectedProductTemplate, selectedProduct, zoneCount);
  const count = getTicketCount(recipients, recipientBatches);
  return vat * count;
};
