export enum TicketType {
  MultipleJourneyTicket = 'MultipleJourneyTicket',
  SingleJourneyTicket = 'SingleJourneyTicket',
}

export const parseTicketType = (value: string): TicketType => {
  const parsed = Object.values(TicketType).find((x) => x === value);

  if (!parsed) throw new Error(`${value} is not a valid TicketType`);
  return parsed;
};
