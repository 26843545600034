import { NationalNumber } from 'libphonenumber-js';

export enum PhoneValidationErrorType {
  PHONE_NUMBER_ERROR_INVALID_FORMAT = 'PHONE_NUMBER_ERROR_INVALID_FORMAT',
  PHONE_NUMBER_ERROR_NOT_MOBILE = 'PHONE_NUMBER_ERROR_NOT_MOBILE',
  PHONE_NUMBER_ERROR_DUPLICATE = 'PHONE_NUMBER_ERROR_DUPLICATE',
}

export interface PhoneValidationError {
  success: false,
  value: string;
  error: PhoneValidationErrorType,
}

export interface PhoneValidationSuccess {
  success: true;
  phoneCountryCode: string;
  phone: NationalNumber;
}

export type PhoneValidationResult = PhoneValidationSuccess | PhoneValidationError;
