import { CtaLink } from '@ruter-ds/rds-components';
import React from 'react';
import ComponentPropTypes from './componentPropTypes';
import './OrderConfirmed.scss';

export interface OrderConfirmedProps {
  type: ComponentPropTypes.OrderConfirmed
  reset: () => void;
}

const OrderConfirmed: React.FC<OrderConfirmedProps> = ({ reset }) => (
  <div className="order-confirmed-page" data-test-id="order-confirmed-section">
    <h1>Vi har mottatt din bestilling.</h1>
    <p>Billettene blir opprettet fortløpende og sendt til mottakerne per sms. For å se fremdrift, gå til ordrehistorikken.</p>
    <CtaLink onClick={reset} data-test-id="reset-widget">
      Ny bestilling
    </CtaLink>
  </div>
);

export default OrderConfirmed;
